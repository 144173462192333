import styled from 'styled-components';

export const Dropback = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(30, 30, 30, 0.7);
	
	.spinner {
		polygon {
			stroke: ${({ theme }) => theme.colors.purplePrimary};
			stroke-width: 2;
		}	
	}
`;
