export const ROLE = {
	ADMIN: 1,
	MODERATOR: 2,
	MEMBER: 3,
};

export const ROLE_NAMES = {
	[ROLE.ADMIN]: 'Admin',
	[ROLE.MODERATOR]: 'Moderator',
	[ROLE.MEMBER]: 'Member',
};

export const MAX_PRIVACY_LEVEL = 4;
export const MAX_FILE_SIZE = 1024 * 1024;

export const EVENTS = {
	"ZUVILLAGE_GEORGIA": "ZuVillageGeorgia",
	"ALEPH": "Aleph",
	"ETH_SAFARI": "ETHSafari",
}

