import { styled } from "styled-components";

export const Container = styled.div`
	position: relative;
`;

export const Content = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.colors.white10};
	padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[3]}`};
	border-radius: ${({ theme }) => theme.spacing[1.5]};
	color: ${({ theme, $selected }) => $selected  ? theme.colors.white : theme.colors.white50};
	cursor: pointer;

	svg {
		width: ${({ theme }) => theme.spacing[5.5]};
		height: ${({ theme }) => theme.spacing[5.5]};
		position: absolute;
		top: 50%;
		right: ${({ theme }) => theme.spacing[3]};
		transform: translateY(-50%);
	}
`;

export const Options = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 1;
	bottom: calc(100% + ${({ theme }) => theme.spacing[2]});
	left: 0;
	width: 100%;
	gap: ${({ theme }) => theme.spacing[2]};
`;

export const Option = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[3]}`};
	border-radius: ${({ theme }) => theme.spacing[1.5]};
	color: ${({ theme }) => theme.colors.black};
	cursor: pointer;
`;

