import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit'
import { getAllNotifications, getSpecificUsers } from '../../app/api';

export const fetchAllNotifications = createAsyncThunk(
	'notifications/fetchAllNotifications',
	async (_, { getState, requestId }) => {
		let notifications = [];
		try {
			notifications = await getAllNotifications();
			let state = getState();

			let userIds = notifications.map(notification => {
				if (notification.metadata) {
					return JSON.parse(notification.metadata).createdById;
				}
				return notification.created_by_id;
			}).filter(id => id);
			let users = [];
			if (userIds.length > 0) {
				users = await getSpecificUsers(userIds);
			}
			notifications.forEach(notification => {
				let senderId = notification.created_by_id;
				let communityId = notification.community_id;
				if (notification.metadata) {
					senderId = JSON.parse(notification.metadata).createdById;
					communityId = JSON.parse(notification.metadata).communityId;
				}

				let user = users.find(user => user.id === senderId);
				let community = state.communities.all.find(community => community.id === communityId);
				notification.sender_name = user?.name || '';
				notification.avatar = user?.avatar_thumbnail_url || '';
				notification.community_name = community?.name;
			});
		} catch (error) {
			console.error(error);
		} finally {
			return notifications;
		}
	}
);

const initialState = {
	loading: false,
	error: null,
	all: [],
}

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setNotificatons: (state, action) => {
			state.all = action.payload;
		},
		addNotification: (state, action) => {
			state.all.push(action.payload);
		},
		removeNotification: (state, action) => {
			state.all = state.all.filter(notification => notification.id !== action.payload);
		},
		updateNotificationStatus: (state, action) => {
			const { created_by_id, invitee_id, community_id, status } = action.payload;
			let notification = state.all.find(
				notification => notification.created_by_id === created_by_id &&
					notification.invitee_id === invitee_id &&
					notification.community_id === community_id
			);
			if (notification) {
				notification.status = status;
				notification.closed = true;
				notification.id = notification.id || Date.now();
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllNotifications.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.all = action.payload;
			})
			.addMatcher(isPending(fetchAllNotifications), (state) => {
				state.loading = true;
				state.error = null;
			})
			.addMatcher(isRejected(fetchAllNotifications), (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

	}
})

export const { setNotificatons, addNotification, removeNotification, updateNotificationStatus } = notificationsSlice.actions

export const selectNotifications = state => state.notifications;

export default notificationsSlice.reducer

