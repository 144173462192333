import styled from 'styled-components';
import spiralBG from '../../../icons/spiral.svg';
import Popup from '../../../components/common/popup/popup';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const Cover = styled.div`
    width: 100%;
    height: 35dvh;
	flex-shrink: 0;
    position: relative;
    background-size: cover;
	background-position: center center;
	background-image: ${({ $bg }) => `url(${$bg})`};
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]}`};

	&>div {
		display: flex;
		justify-content: space-between;
	
		>svg {
			background-color: ${({ theme }) => theme.colors.white};	
			padding: ${({ theme }) => theme.spacing[1]};
			border-radius: ${({ theme }) => theme.spacing[3]};
			cursor: pointer;
		}
	}
`;

export const Content = styled.div`
	flex-grow: 1;
	position: relative;
    padding: ${({ theme }) => theme.spacing[8]};
    color: #fff;
	background: rgba(12, 1, 11, 1);
    background: linear-gradient(125deg, rgba(196, 6, 97, 1) -10%, rgba(12, 1, 11, 1) 25%, rgba(12, 1, 11, 1) 75%, rgba(48, 28, 131, 1) 110%);

	&>img {
		position: absolute;
		width: 100%;
		transform: scale(2) rotate(30deg);
		opacity: 0.2;
	}
`;

export const Properties = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	&>div {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing[3]};
		
		h1 {
			font-size: ${({ theme }) => theme.spacing[6]};
			font-weight: 600;
			color: ${({ theme }) => theme.colors.white};
		}
		
		span {
			font-size: ${({ theme }) => theme.spacing[4]};
			color: ${({ theme }) => theme.colors.white50};
		}
	}
`;

export const TelegramButton = styled.a`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[2]};
	padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[6]}`};
	border: 1px solid ${({ theme }) => theme.colors.white50};
	border-radius: 100px;
	background-color: transparent;
	color: ${({ theme }) => theme.colors.white};
	font-size: ${({ theme }) => theme.spacing[4]};
	cursor: pointer;
	text-decoration: none;

	span {
		flex-shrink: 0;
	}

	svg {
		width: ${({ theme }) => theme.spacing[5.5]};
		height: ${({ theme }) => theme.spacing[5.5]};
	}
`;


export const Description = styled.p`
	margin-top: ${({ theme }) => theme.spacing[6]};
	color: ${({ theme }) => theme.colors.white};
	font-size: ${({ theme }) => theme.spacing[4.5]};
	line-height: 1.8;
	white-space: pre-line;
`;

export const ReadMore = styled.button`
	margin-top: ${({ theme }) => theme.spacing[8]};
	padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[6]}`};
	border: none;
	border-radius: 100px;
	background-color: ${({ theme }) => theme.colors.white10};
	font-size: ${({ theme }) => theme.spacing[4]};
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
`;

export const Box = styled.div`
	display: flex;
	justify-content: space-between;
	gap: ${({ theme }) => theme.spacing[20]};
	align-items: center;
	
	margin-top: ${({ theme }) => theme.spacing[8]};
	padding: ${({ theme }) => theme.spacing[4]};
	border-radius: ${({ theme }) => theme.spacing[3]};

	background-color: ${({ theme }) => theme.colors.pink10};
	/* background-image: url(${spiralBG}); */
	/* background-repeat: no-repeat; */
	/* background-position: right center; */

	&>div {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing[4]};

		h3 {
			font-size: ${({ theme }) => theme.spacing[6]};
			font-weight: 600;
			color: ${({ theme }) => theme.colors.white};
			text-transform: capitalize;
		}
		
		p {
			color: ${({ theme }) => theme.colors.white};
			font-size: ${({ theme }) => theme.spacing[4]};
			line-height: 1.4;
		}

	}
	
	&>svg {
		flex-grow: 0;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${({ theme }) => theme.colors.white};
		padding: ${({ theme }) => theme.spacing[2]};
		border: none;
		border-radius: ${({ theme }) => theme.spacing[3]};
		cursor: pointer;
	}
	
`;

export const Footer = styled(Popup)`
	.buttons {
		flex-direction: column;
		p {
			text-align: center;
			color: ${({ theme }) => theme.colors.white};
		}
	}
`;

