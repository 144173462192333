import { POPUP_TYPE } from '../common/popup/constants';
import * as Styled from './styles';
import { ReactComponent as CloseIcon } from '../../icons/x-small.svg';
import { useState } from 'react';

export default function Tutorial({ className, onClose, texts = [], ...props }) {
	const [step, setStep] = useState(0);

	return (
		<Styled.Container
			className={className}
			isOpen={true}
			onClickOutside={onClose}
		>
			<CloseIcon onClick={onClose} />
			<Styled.Bars>
				{
					texts.map((_, index) => (
						<Styled.Bar
							key={index}
							onClick={() => setStep(index)}
							$complete={index <= step}
						/>
					))
				}
			</Styled.Bars>
			<p>{texts[step]}</p>
		</Styled.Container>
	);
}

