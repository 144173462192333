import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { removeCommunity, selectCommunitiesLoading, selectCommunityById } from '../communitiesSlice';
import * as Styled from './community.styles';
import { ReactComponent as BackIcon } from '../../../icons/back.svg';
import { ReactComponent as ForwardIcon } from '../../../icons/forward.svg';
import { ReactComponent as ThreeDotsIcon } from '../../../icons/dots-vertical.svg';
import { ReactComponent as TelegramIcon } from '../../../icons/telegram.svg';
import { ReactComponent as ElementIcon } from '../../../icons/element-icon.svg';
import { joinCommunity, selectUser } from '../../user/userSlice';
import Popup from '../../../components/common/popup/popup';
import MoreMenu from '../../../components/common/moreMenu';
import { deleteCommunity, getUserVerifications, joinToCommunity, requestVerificationWithEmail, verifyWithEmail } from '../../../app/api';
import { usePopup } from '../../../providers/PopupContextProvider';
import { POPUP_TYPE } from '../../../components/common/popup/constants';
import Spinner from '../../../components/common/spinner';
import { ROLE } from '../constants';
import { getUserPermissionLevel, isUserMember } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { GATING_MECHANISMS } from '../../user/constants';
import ZupassButton from '../../user/auth/zupassButton';

export default function Community() {
	let { id } = useParams();
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { showPopup, closePopup } = usePopup();
	const [showPreview, setShowPreview] = useState(true);
	const user = useSelector(selectUser);

	const community = useSelector(state => selectCommunityById(state, id));
	const loading = useSelector(selectCommunitiesLoading);
	const [deleting, setDeleting] = useState(false);
	const [joining, setJoining] = useState(false);
	const [verifying, setVerifying] = useState(false);
	const [fetchingVerifications, setFetchingVerifications] = useState(false);
	const [shouldVerify, setShouldVerify] = useState(false);

	const descriptionPreview = useMemo(() => {
		if (!community) return '';
		const maxLength = 300;
		return community.description.length > maxLength + 3 ? `${community.description.slice(0, maxLength)}...` : community.description;
	}, [community]);

	function invite() {
		navigate(`/users?community=${id}`);
	}

	async function join() {
		try {
			setJoining(true);
			await joinToCommunity(id);
			dispatch(joinCommunity(id));
			showPopup({
				title: t('messages.community_joined'),
				type: POPUP_TYPE.ALERT,
				lockUI: true,
				buttons: [
					{
						label: t('common.okay'),
						onClick: () => {
							closePopup();
						},
					},
				]
			});
		} catch (error) {
			console.error(error);
		} finally {
			setJoining(false);
		}
	}

	async function remove() {
		showPopup({
			type: POPUP_TYPE.ALERT,
			lockUI: true,
			title: t('messages.delete_community_warning'),
			buttons: [
				{
					label: t('common.cancel'),
					secondary: true,
					onClick: () => {
						closePopup();
					},
				},
				{
					label: t('common.delete'),
					onClick: async () => {
						try {
							setDeleting(true);
							await deleteCommunity(id);
							dispatch(removeCommunity(id));
							navigate('/communities');
							closePopup();
						} catch (error) {
							console.error(error);
						} finally {
							setDeleting(false);
						}
					},
				},
			]
		});
	}

	const startVerification = useCallback(async () => {
		try {
			setVerifying(true);
			await requestVerificationWithEmail(user.email, community.id);
			setShouldVerify(false);
			showPopup({
				title: t('messages.succesfully_verified'),
				type: POPUP_TYPE.ALERT,
				lockUI: true,
				buttons: [
					{
						label: t('common.close'),
						onClick: () => {
							closePopup();
						},
					},
				]
			});
		} catch (e) {
			navigate(`/verify/${community.gated_by}?communityId=${community.id}`)
		} finally {
			setVerifying(false);
		}
	}, [closePopup, community, navigate, showPopup, t, user.email]);

	const isAdmin = useMemo(() => {
		if (!user || !community) return false;
		return community.current_user_role === ROLE.ADMIN;
	}, [user, community]);

	const isMember = useMemo(() => {
		if (!user || !community) return false;
		return isUserMember(id);
	}, [community, id, user]);

	const permissionLevel = useMemo(() => {
		if (!user || !community) return 0;
		return getUserPermissionLevel(id)
	}, [community, id, user]);

	useEffect(() => {
		if (!community || isMember || community.gating_mechanism == null) return;
		if (
			community.gating_mechanism === GATING_MECHANISMS.COMMUNITY &&
			user.communities.some(com => com.id === community.gated_by)
		) {
			return;
		}
		setFetchingVerifications(true);
		async function getVerifications() {
			try {
				const verifications = await getUserVerifications();
				if (!verifications.includes(community.gated_by)) {
					setShouldVerify(true);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setFetchingVerifications(false);
			}
		}
		getVerifications();
	}, [community, isMember, user.communities]);

	const onZupassAuthStart = useCallback(() => {
		setJoining(true);
	}, []);

	const onZupassAuthSuccess = useCallback(() => {
		setJoining(false);
		setShouldVerify(false);
	}, []);

	const onZupassAuthFail = useCallback(() => {
		setJoining(false);
	}, []);

	if (!community) return null;

	return (
		<Styled.Container>
			{(deleting || joining || loading || fetchingVerifications || verifying) && <Spinner />}
			<Styled.Cover $bg={community.avatar_url}>
				<div>
					<BackIcon onClick={() => navigate("/communities")} />
					{isAdmin && (
						<MoreMenu
							sharp
							items={[
								{ label: t('communities.edit_community'), onClick: () => navigate(`/communities/${id}/edit`) },
								{ label: t('communities.delete_community'), onClick: remove },
							]}
						/>
					)}
				</div>
			</Styled.Cover>
			<Styled.Content>
				{/* <img src={ComuminityOverlayImg} alt="overlay" /> */}
				<Styled.Properties>
					<div>
						<h1>{community.name}</h1>
						<span>@{community.handle}</span>
					</div>
					{isMember && community.tg_link && (
						<Styled.TelegramButton href={community.tg_link} target="_blank">
							<span>{t('communities.join_telegram')}</span>
							<TelegramIcon />
						</Styled.TelegramButton>
					)}
				</Styled.Properties>
				<Styled.Description>
					{showPreview ? descriptionPreview : community.description}
				</Styled.Description>
				{(community.description?.length || 0) > descriptionPreview.length && (
					<Styled.ReadMore onClick={() => setShowPreview(!showPreview)}>
						{showPreview ? t('communities.read_more') : t('communities.read_less')}
					</Styled.ReadMore>
				)}
				<Styled.Box>
					<div>
						<h3>{t('communities.community_members')}</h3>
						{(!isMember && permissionLevel < 2) ?
							<p>{t('messages.members_benefits')}</p> :
							<p>{t('messages.members_description')}</p>
						}
					</div>
					{(isMember || permissionLevel >= 2) ? <ForwardIcon onClick={() => navigate(`/communities/${id}/members`)} /> : <div></div>}
				</Styled.Box>
				<Styled.Box>
					<div>
						<h3>{t('common.polls')}</h3>
						{isMember ?
							<p>{t('messages.polls_description')}</p> :
							<p>{t('messages.polls_benefits')}</p>}
					</div>
					{isMember && <ForwardIcon onClick={() => navigate(`/communities/${id}/polls`)} />}
				</Styled.Box>
			</Styled.Content>
			{isAdmin && (
				<Popup isOpen>
					<div className='buttons'>
						{/* <button onClick={userJoined ? invite : join}>{userJoined ? 'Invite a member' : 'Request to join'}</button> */}
						<button onClick={invite}>{t('common.invite')}</button>
					</div>
				</Popup>
			)}
			{!isMember && (shouldVerify || community.is_permissionless) && (
				<Styled.Footer isOpen>
					<div className='buttons'>
						{shouldVerify ? (
							<>
								{community.gating_mechanism === GATING_MECHANISMS.ZUPASS_VERIFIED_ATTENDANCE ? (
									<ZupassButton
										verify
										text={t('common.verify')}
										onAuthStart={onZupassAuthStart}
										onAuthSuccess={onZupassAuthSuccess}
										onAuthFail={onZupassAuthFail}
										active
									/>
								) : (
									<button onClick={startVerification}>{t('common.verify')}</button>
								)}
								<p>{t('communities.private_community_verify')}</p>
							</>
						) : (
							<button onClick={join}>{t('common.join')}</button>
						)}
					</div>
				</Styled.Footer>
			)}
		</Styled.Container >
	);
}
