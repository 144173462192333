import styled from 'styled-components';

export const Outer = styled.div`
	padding: ${({ theme }) => theme.spacing[0.5]};
	width: 100%;
	height: ${({ theme }) => theme.spacing[5]};
	border: 1px solid ${({ theme }) => theme.colors.white10};
	border-radius: 100px;
`;

export const Inner = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 100px;
	background-color: ${({ theme }) => theme.colors.white10};
	overflow: hidden;
`;

export const Bar = styled.div`
	width: ${({ width }) => width}%;
	height: 100%;
	background: rgb(66,27,149);
	background: linear-gradient(160deg, rgba(66,27,149,1) 0%, rgba(125,69,247,1) 100%);
`;

