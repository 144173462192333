import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[2]};	
	padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[5]}`};
	background-color: ${({ theme }) => theme.colors.white05};
	border: 1px solid ${({ theme }) => theme.colors.white10};
	border-radius: ${({ theme }) => theme.spacing[3]};
	
	svg {
		width: ${({ theme }) => theme.spacing[8]};
		height: ${({ theme }) => theme.spacing[8]};
	}
	
	div {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing[2]};
		
		h5 {
			font-size: ${({ theme }) => theme.spacing[4.5]};
			font-weight: 500;
			color: ${({ theme }) => theme.colors.white};
		}

		p {
			font-size: ${({ theme }) => theme.spacing[4]};
			color: ${({ theme }) => theme.colors.white50};
		}
	}
`;
