import styled from 'styled-components';
import ZupassButton from './zupassButton';

function getEventBackground(event) {
	switch (event) {
		case 'zuzalu':
			return 'linear-gradient(-135deg, #202E29 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%)';
		default:
			return 'initial';
	}
}

export const Container = styled.div`
	position: relative;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[14]}`};
	background: ${({ event }) => getEventBackground(event)};
`;

export const Content = styled.div`
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: ${({ theme }) => theme.spacing[120]};
	
	h2 {
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[7]};
	}
	
	h1 {
		margin-top: ${({ theme }) => theme.spacing[1.5]};
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[12]};
		line-height: 1.25;
		text-align: center;
	}

`;

export const Logo = styled.div`
	position: absolute;
	top: ${({ theme }) => `-${theme.spacing[20]}`};
	left: 50%;
	transform: translate(-50%, -100%);
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[5]};
	align-items: center;
	
	img {
		width: ${({ theme }) => theme.spacing[22]};
	}

	svg {
		width: ${({ theme }) => theme.spacing[30]};
	}
`;

export const Tabs = styled.div`
	margin-top: ${({ theme }) => theme.spacing[12]};
	margin-bottom: ${({ theme }) => theme.spacing[8]};
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: ${({ theme }) => theme.spacing[3]};
	
	button {
		flex-grow: 1;
		padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[5]}`};
		border-radius: 100px;
		border: none;
		background-color: ${({ theme }) => theme.colors.white10};
		font-size: ${({ theme }) => theme.spacing[4]};
		font-weight: 500;
		color: ${({ theme }) => theme.colors.white};	
		cursor: pointer;
		
		&.active {
			background-color: ${({ theme }) => theme.colors.purplePrimary};
		}
	}
`;

export const ZupassLogin = styled(ZupassButton)`
	position: absolute;
	bottom: ${({ theme }) => theme.spacing[10]};
	left: 50%;
	transform: translateX(-50%);
	border: none;
	background: none;
	text-decoration: underline;
	color: ${({ theme }) => theme.colors.white80};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	font-weight: 500;
	cursor: pointer;
`;

export const Suggestion = styled.p`
	margin-top: ${({ theme }) => theme.spacing[4.5]};
	color: ${({ theme }) => theme.colors.white80};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	font-style: italic;
`;

export const LearnMore = styled.a`
	margin-top: ${({ theme }) => theme.spacing[8]};
	color: ${({ theme }) => theme.colors.white80};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	cursor: pointer;
	text-decoration: underline;
`;

