import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Styled from './styles';
import { ReactComponent as BackIcon } from '../../../../icons/back.svg';
import { ReactComponent as EmailIcon } from '../../../../icons/email.svg';
import { ReactComponent as LockIcon } from '../../../../icons/lock-closed.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getAuthData, registerWithHash, resetPasswordWithHash } from '../../../../app/api';
import { useDispatch } from 'react-redux';
import { setUser } from '../../userSlice';
import { isValidEmail, validatePassword } from '../../../../utils/helpers';

export default function ResetPassword({ onBoarding = false }) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const [email, setEmail] = useState(searchParams.get('email') || '');
	const hash = searchParams.get('hash') || '';
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [error, setError] = useState('');
	const [showConfirmation, setShowConfirmation] = useState(false);

	async function send(e) {
		e.preventDefault();
		try {
			let passwordError = validatePassword(password);
			if (passwordError) {
				setError(passwordError);
				return;
			}
			if (password !== confirmPassword) {
				setError(t('auth.passwords_do_not_match'));
				return;
			}

			setError('');
			if (onBoarding) {
				await registerWithHash(email, password, hash);
				try {
					const userData = await getAuthData();
					dispatch(setUser(userData));
					navigate('/');
				} catch (error) {
					navigate('/login');
				}
			} else {
				await resetPasswordWithHash(email, password, hash);
				try {
					const userData = await getAuthData();
					dispatch(setUser(userData));
					setShowConfirmation(true);
				} catch (error) {
					navigate('/login');
				}
			}
		} catch (e) {
			setError(e.message);
		}
	}

	function goBack() {
		navigate('/login');
	}

	useEffect(() => {
		if (showConfirmation) {
			setTimeout(() => {
				navigate('/');
			}, 1500);
		}
	}, [navigate, showConfirmation]);

	const disableButton = !isValidEmail(email) || password === '' || confirmPassword === '';

	return (
		<Styled.Container>
			<BackIcon onClick={goBack} />
			<Styled.Headling>{t("auth.community_graphs")} </Styled.Headling>
			{!showConfirmation ? (
				<form onSubmit={send}>
					<Styled.Hint>{onBoarding ? t("auth.create_password") : t("auth.reset_password_below")}</Styled.Hint>
					<Styled.CustomInput
						placeholder={t("common.email")}
						Icon={EmailIcon}
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<Styled.CustomInput
						placeholder={t("auth.new_password")}
						Icon={LockIcon}
						type="password"
						onChange={e => setPassword(e.target.value)}
					/>
					<Styled.CustomInput
						placeholder={t("auth.confirm_new_password")}
						Icon={LockIcon}
						type="password"
						onChange={e => setConfirmPassword(e.target.value)}
					/>
					<Styled.PasswordRules>{t("auth.password_rules")}</Styled.PasswordRules>
					<Styled.ErrorText $hidden={!Boolean(error)}>{error}</Styled.ErrorText>
					<Styled.Button disabled={disableButton}>{onBoarding ? t('auth.login') : t('common.reset')}</Styled.Button>
					<Styled.LearnMore href="https://www.maitri.network/communitygraphs" target="_blank">
						{t("common.learn_more")}
					</Styled.LearnMore>
				</form>
			) : (
				<Styled.Confirmation>
					<p><Trans i18nKey="auth.password_succesfully_reset" components={{ br: <br /> }} /></p>
				</Styled.Confirmation>
			)}
		</Styled.Container>
	);
}

