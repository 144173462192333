import React, { useCallback, useState } from 'react';
import * as Styled from './auth.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import zuzuluImage from '../../../images/zuzulu-city.png';
import { ReactComponent as ZuzuluLogo } from '../../../icons/zuzulu-city.svg';
import Login from './login';
import Signup from './signup';

export default function Auth() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { event } = useParams();
	const [mode, setMode] = useState('login');
	const [selectedMethod, setSelectedMethod] = useState(null);
	const [emailSent, setEmailSent] = useState(false);

	const EventLogo = () => {
		switch (event) {
			case 'zuzalu':
				return (
					<Styled.Logo>
						<img src={zuzuluImage} alt="Zuzalu City" />
						<ZuzuluLogo />
					</Styled.Logo>
				);
			default:
				return null;
		}
	}

	const onZupassAuthStart = useCallback(() => {
		setSelectedMethod('zupass');
	}, []);

	const onZupassAuthSuccess = useCallback(() => {
		navigate('/');
	}, [navigate]);

	const onZupassAuthFail = useCallback(() => {
		setSelectedMethod(null);
	}, []);

	return (
		<Styled.Container event={event}>
			<Styled.Content>
				<EventLogo />
				<Trans
					i18nKey="auth.welcome_message"
					components={{ h2: <h2 />, h1: <h1 /> }}
				/>
				{!emailSent && (
					<Styled.Tabs>
						<button className={mode === 'login' ? 'active' : ''} onClick={() => setMode("login")}>{t("auth.login")}</button>
						<button className={mode === 'signup' ? 'active' : ''} onClick={() => setMode("signup")}>{t("auth.sign_up")}</button>
					</Styled.Tabs>
				)}
				{mode === 'login' ? <Login /> : <Signup onSend={() => setEmailSent(true)} />}
				{!emailSent && (
					<>
						<Styled.Suggestion>(Chrome browser works best)</Styled.Suggestion>
						<Styled.LearnMore href="https://www.maitri.network/communitygraphs" target="_blank">
							{t("common.learn_more")}
						</Styled.LearnMore>
						{mode === 'login' && <Styled.ZupassLogin
							text={t("auth.login_zupass")}
							onAuthStart={onZupassAuthStart}
							onAuthSuccess={onZupassAuthSuccess}
							onAuthFail={onZupassAuthFail}
							disabled={Boolean(selectedMethod)}
							active={selectedMethod === 'zupass'}
						/>}
					</>
				)}
			</Styled.Content>
		</Styled.Container>
	);
}
