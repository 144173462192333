import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: ${({ theme }) => theme.spacing[10]};
	height: ${({ theme }) => theme.spacing[10]};
	background-color: ${({ theme }) => theme.colors.white10};
	border: 1px solid ${({ theme }) => theme.colors.white10};
	border-radius: ${({ theme }) => theme.spacing[1.5]};
	
	svg {
		width: ${({ theme }) => theme.spacing[6]};
		height: ${({ theme }) => theme.spacing[6]};
		fill: ${({ theme }) => theme.colors.white10};
	}
`;

