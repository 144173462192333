import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Styled from './poll.styles';
import { format } from 'date-fns';
import { usePopup } from '../../../providers/PopupContextProvider';
import { useDispatch, useSelector } from 'react-redux'; import { fetchPoll, selectCommunitiesLoading, selectPollById } from '../communitiesSlice'; import Spinner from '../../../components/common/spinner'; import { deletePoll, storePollVote } from '../../../app/api';
import { useNavigate, useParams } from 'react-router-dom';
import { POPUP_TYPE } from '../../../components/common/popup/constants';
import Popup from '../../../components/common/popup/popup';
import { selectUser } from '../../user/userSlice';
import { Trans, useTranslation } from 'react-i18next';

function formatDatetime(date) {
	const d = new Date(date);
	return format(d, "MMMM d, h:mma").replace('AM', 'am').replace('PM', 'pm');
}

export default function VotePoll() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { pollId } = useParams();
	const { showPopup, closePopup } = usePopup();
	const loading = useSelector(selectCommunitiesLoading);
	const poll = useSelector(state => selectPollById(state, pollId));
	const user = useSelector(selectUser);

	const [storing, setStoring] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [shouldVote, setShouldVote] = useState(true);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [fetched, setFetched] = useState(false);

	function vote(index) {
		if (!shouldVote) return;
		setSelectedIndex(index);
	}

	const store = useCallback(async () => {
		if (selectedIndex === -1) return;
		try {
			setStoring(true);
			await storePollVote(poll.id, poll.options[selectedIndex].option_number);
			setShouldVote(false);
			dispatch(fetchPoll(pollId));
			closePopup();
		} catch (error) {
			console.error(error);
		} finally {
			setStoring(false);
		}
	}, [closePopup, dispatch, poll, pollId, selectedIndex]);

	const actions = useMemo(() => {
		if (!poll) return [];
		if (poll.time_remaining <= 0) {
			return [
				{
					label: 'Close',
					onClick: () => {
						closePopup();
						navigate(`/communities/${poll.community_id}/polls`);
					},
				},
			];
		};
		if (!shouldVote) {
			return [
				{
					label: 'Close',
					secondary: true,
					onClick: () => {
						closePopup();
						navigate(`/communities/${poll.community_id}/polls`);
					},
				},
				{
					label: 'Re-vote',
					onClick: () => {
						setShouldVote(true);
					},
				},
			];
		} else {
			return [
				{
					label: 'Close',
					secondary: true,
					onClick: () => {
						setSelectedIndex(-1);
						closePopup();
						navigate(`/communities/${poll.community_id}/polls`);
					},
				},
				{
					label: 'Vote',
					onClick: () => {
						store();
					},
				},
			];
		}
	}, [closePopup, navigate, poll, shouldVote, store]);

	function remove() {
		showPopup({
			type: POPUP_TYPE.ALERT,
			lockUI: true,
			title: t('messages.delete_poll_warning'),
			buttons: [
				{
					label: t('common.cancel'),
					secondary: true,
					onClick: () => {
						closePopup();
					},
				},
				{
					label: t('common.delete'),
					onClick: async () => {
						try {
							setDeleting(true);
							await deletePoll(poll.id);
							navigate(`/communities/${poll.community_id}/polls`);
							closePopup();
						} catch (error) {
							console.error(error);
						} finally {
							setDeleting(false);
						}
					},
				},
			]
		});
	}

	useEffect(() => {
		if (poll && poll.options) {
			const userVote = poll.options.find(option => option.user_vote);
			if (userVote || poll.time_remaining <= 0) {
				setShouldVote(false);
				setSelectedIndex(poll.options.indexOf(userVote));
			}
		}
	}, [poll]);

	useEffect(() => {
		if (!loading && !fetched) {
			dispatch(fetchPoll(pollId));
			setFetched(true);
		}
	}, [dispatch, fetched, loading, pollId]);

	if (!poll) return <Spinner />;

	return (
		<Styled.Container>
			{(!fetched || storing || deleting) && <Spinner />}
			<Styled.Header>
				<div>
					<div>
						<span><Trans i18nKey="polls.poll_created_by.wrapped" components={{ br: <br /> }} /></span>
						<span>{poll.created_by_name}</span>
					</div>
					<div>
						<span><Trans i18nKey="polls.poll_started.wrapped" components={{ br: <br /> }} /></span>
						<span>{formatDatetime(poll.start_time)}</span>
					</div>
					<div>
						<span><Trans i18nKey="polls.poll_ends.wrapped" components={{ br: <br /> }} /></span>
						<span>{poll.time_remaining <= 0 ? 'CLOSED' : formatDatetime(poll.end_time)}</span>
					</div>
					<div>
						<span><Trans i18nKey="polls.poll_membership_level_weight.wrapped" components={{ br: <br /> }} /></span>
						<span>{poll.weighting}</span>
					</div>
				</div>
				<div>
					<h1>{poll.question}</h1>
				</div>
			</Styled.Header>
			<Styled.Options>
				{poll.options && poll.options.map((option, index) => (
					<Styled.Option key={option.option_number} $shouldVote={shouldVote} onClick={() => vote(index)}>
						{shouldVote && <Styled.Checkbox selected={selectedIndex === index}><div></div></Styled.Checkbox>}
						<Styled.OptionText>
							<span>{option.option_text}</span>
							{!shouldVote && option.user_vote && (<span>({t('common.your_choice')})</span>)}
						</Styled.OptionText>
						{!shouldVote && (
							<>
								<Styled.OptionResult>
									{Math.round(option.voted_for_proportion * 100)}%
								</Styled.OptionResult>
								<Styled.Bar $width={Math.round(option.voted_for_proporiton * 100)} $voted={option.user_vote} />
							</>
						)}
					</Styled.Option>
				))}
			</Styled.Options>
			{!shouldVote && (
				<Styled.Stats>
					<span>{Math.round(poll.voted_total_weight * 100)}{t('messages.percent_of_community_votes_cast')}</span>
				</Styled.Stats>
			)}
			{user.id === poll.created_by && <Styled.DeleteButton onClick={remove}>{t('polls.delete_poll')}</Styled.DeleteButton>}
			<Popup isOpen>
				<div className='buttons'>
					{actions.map(({ label, onClick, secondary }) => (
						<button key={label} onClick={onClick} className={secondary && 'secondary'}>{label}</button>
					))}
				</div>
			</Popup>
		</Styled.Container>
	)
}
