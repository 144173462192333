import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	textarea {
		width: 100%;
		display: flex;
		padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[5.5]}`};
		background-color: ${({ theme }) => theme.colors.white10};
		color: ${({ theme }) => theme.colors.white};
		border-radius: ${({ theme }) => theme.spacing[1.5]};
		border: none;
		font-family: inherit;
		font-size: ${({ theme }) => theme.spacing[4]};
		resize: none;
		
		&::placeholder {
			color: ${({ theme }) => theme.colors.white25};
		}

		&:focus {
			outline: none;
		}

	}	
`;
