import styled from 'styled-components';
import Popup from '../../../components/common/popup/popup';

export const Container = styled.div`
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[14]}`};
	display: flex;
	flex-direction: column;
	min-height: calc(100dvh - 150px);
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;

	>div:first-child {
		svg {
			background-color: ${({ theme }) => theme.colors.white10};	
			padding: ${({ theme }) => theme.spacing[1]};
			border-radius: ${({ theme }) => theme.spacing[3]};
			cursor: pointer;
			
			path {
				fill: ${({ theme }) => theme.colors.white};
			}
		}

	}
`;

export const AvatarUpload = styled.div`
	margin-top: ${({ theme }) => theme.spacing[10]};
    display: flex;
    flex-direction: column;
    align-items: center;
	
	>span {
		margin-top: ${({ theme }) => theme.spacing[4]};
		font-size: ${({ theme }) => theme.spacing[4]};
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white};
		text-decoration: underline;
	}

	input {
		display: none;
	}
`;

export const InputsWrapper = styled.div`
	flex-grow: 1;
	margin-top: ${({ theme }) => theme.spacing[8]};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[4]};
`;

export const Instruction = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[6]};
	color: ${({ theme }) => theme.colors.white};
	
	h5 {
		font-size: ${({ theme }) => theme.spacing[4.5]};
		font-weight: 600;
		text-align: center;
	}
	
	p {
		width: 80%;
		font-size: ${({ theme }) => theme.spacing[4]};
		line-height: 1.25;
		font-weight: 300;
		text-align: center;
	}
`;

export const Footer = styled(Popup)`
	.content {
		gap: ${({ theme }) => theme.spacing[3]};
	}

	.error {
		max-width: 80%;
		align-self: center;
		padding: ${({ theme }) => theme.spacing[3.5]};
		background-color: ${({ theme }) => theme.colors.magenta10};
		border: 1px solid ${({ theme }) => theme.colors.magenta50};
		border-radius: ${({ theme }) => theme.spacing[1]};
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[3.5]};
		font-weight: 300;
	}

	button:disabled {
		opacity: 0.3;
	}
`;

