import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Attestations from '../attestations/Attestations';
import { fetchCommunityMembersAndAttestations, selectCommunitiesLoading, selectCommunityById } from '../communitiesSlice';
import { ReactComponent as BackIcon } from '../../../icons/back.svg';
import { ReactComponent as ArrowUpIcon } from '../../../icons/arrow-up.svg';
import * as Styled from './members.styles';
import Progress from '../../../components/common/progress';
import { ROLE_NAMES } from '../constants';
import Spinner from '../../../components/common/spinner';
import { getInitials, getUserPermissionLevel, isUserMember } from '../../../utils/helpers';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import AttestationsCanvasManager from "../attestations/canvas/objects/AttestationsCanvasManager";
import {INITIAL_CIRCLE_RADIUS} from "../attestations/canvas/constants";
import Tutorial from "../../../components/tutorial";
import { ReactComponent as QuestionIcon } from '../../../icons/question.svg';

export default function Members() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const theme = useTheme();
	let { id } = useParams();

	const community = useSelector(state => selectCommunityById(state, id));
	const communitisLoading = useSelector(selectCommunitiesLoading);

	const [showAttestations, setShowAttestations] = useState(false);
	const [fetched, setFetched] = useState(false);
	const [attestedMemberIds, setAttestedMemberIds] = useState([]);
	const [originalAttestationIds, setOriginalAttestationIds] = useState([]);
	const [showTutorial, setShowTutorial] = useState(false);

	const movingAvatarRef = useRef();
	const contentRef = useRef();
	const dragState = useRef({
		isDragging: false,
		id: null,
	});

	const permissionLevel = useMemo(() => {
		if (!community) return 0;
		return getUserPermissionLevel(id)
	}, [community, id]);

	const isMember = useMemo(() => {
		if (!community) return false;
		return isUserMember(id)
	}, [community, id]);

	const areMembersAttested = useMemo(() => {
		if (!community || !fetched) return false;
		return community.members.some(member => member.membership_level);
	}, [community, fetched]);


	const addAttestedMemberId = (id) => {
		setAttestedMemberIds((currentAttestedMemberIds) => {
			if (!currentAttestedMemberIds.includes(id)) {
				return [...currentAttestedMemberIds, id];
			}

			return currentAttestedMemberIds;
		});
	};

	const removeAttestedMemberId = (id) => {
		setAttestedMemberIds((currentAttestedMemberIds) => {
			if (currentAttestedMemberIds.includes(id)) {
				const filtered = currentAttestedMemberIds.filter(memberId => memberId !== id);
				return filtered;
			}

			return currentAttestedMemberIds;
		});
	};

	useEffect(() => {
		if (community && !fetched) {
			dispatch(fetchCommunityMembersAndAttestations(community.id));
			setFetched(true);
		}
	}, [community, dispatch, fetched]);

	useEffect(() => {
		if (community && community.members) {
			const attestedMembers = community.members.filter(member => member.attestation?.score);
			const attestedMemberIds = attestedMembers.map(member => member.id);
			setAttestedMemberIds(attestedMemberIds);
			setOriginalAttestationIds(attestedMemberIds);
		}
	}, [community]);

	function resetAttestations() {
		setAttestedMemberIds(originalAttestationIds);
	}

	function startDraggingAvatar(e, id) {
		if (!showAttestations) return;
		e.preventDefault();
		const member = community.members.find(member => member.id === id);
		if (!isMember || member === community.members[0]) return;
		if (attestedMemberIds.includes(id)) {
			const attestationsCanvasManager = AttestationsCanvasManager.getInstance();
			attestationsCanvasManager.focusOnMember(id)
			return;
		}
		dragState.current.isDragging = true;
		dragState.current.id = id;
		movingAvatarRef.current.style.display = 'block';
		movingAvatarRef.current.style.top = `${e.clientY - contentRef.current.offsetTop}px`;
		movingAvatarRef.current.style.left = `${e.clientX - contentRef.current.offsetLeft}px`;
		let size;
		if (member.avatar_thumbnail_url) {
			movingAvatarRef.current.children[0].style.display = 'block';
			movingAvatarRef.current.children[0].src = community.members.find(member => member.id === id).avatar_thumbnail_url;
			movingAvatarRef.current.children[1].style.display = 'none';
			movingAvatarRef.current.children[1].textContent = '';
			size = `${INITIAL_CIRCLE_RADIUS * 2 + 5}px`;
		} else {
			movingAvatarRef.current.children[0].src = '';
			movingAvatarRef.current.children[0].style.display = 'none';
			movingAvatarRef.current.children[1].style.display = 'flex';
			movingAvatarRef.current.children[1].textContent = getInitials(community.members.find(member => member.id === id).name);
			size = `${INITIAL_CIRCLE_RADIUS * 2}px`;
		}
		movingAvatarRef.current.style.width = size;
		movingAvatarRef.current.style.height = size;
	}

	function dragAvatar(e) {
		e.preventDefault();
		if (!dragState.current.isDragging) return;
		movingAvatarRef.current.style.top = `${e.clientY - contentRef.current.offsetTop}px`;
		movingAvatarRef.current.style.left = `${e.clientX - contentRef.current.offsetLeft}px`;
	}

	useEffect(() => {
		function pointerUpHandler(e) {
			e.preventDefault();
			movingAvatarRef.current.style.display = 'none';

		}
		function touchMoveHandler(e) {
			if (dragState.current.isDragging) {
				e.preventDefault();
			}
		}
		document.addEventListener('pointerup', pointerUpHandler);
		document.addEventListener('touchmove', touchMoveHandler, { passive: false });

		return () => {
			document.removeEventListener('pointerup', pointerUpHandler);
			document.removeEventListener('touchmove', touchMoveHandler);
		}
	}, []);

	const attestationsEnabled = isMember && permissionLevel >= 3 && community.members.length >= 3;

	if (!fetched || communitisLoading) {
		return <Spinner />;
	}


	return (
		<Styled.Container onPointerMove={dragAvatar} >
			<Styled.Header>
				<BackIcon className="back-icon" onClick={() => navigate(`/communities/${id}`)} />
				<div>
					<h1>{t('common.membership_levels')}</h1>
					<span>{community.name}</span>
				</div>
				<QuestionIcon className="question-icon" onClick={() => setShowTutorial(true)}/>
			</Styled.Header>
			{(!isMember && permissionLevel < 3) ? (
				<Styled.InfoBox
					title={t('messages.membership_levels_for_members.title')}
					description={t('messages.membership_levels_for_members.description')}
				/>
			) : !isMember ? (
				<Styled.InfoBox
					title={t('messages.attestations_for_members.title')}
					description={t('messages.attestations_for_members.description')}
				/>
			): ''}
			{(isMember && community.members.length < 3) ? (
				<Styled.InfoBox
					title={t('messages.attestations_three_required.title')}
					description={t('messages.attestations_three_required.description')}
				/>
			) : ((isMember || permissionLevel >= 3) && !areMembersAttested) ? (
				<Styled.InfoBox
					title={t('messages.membership_levels_not_enough_members.title')}
					description={t('messages.membership_levels_not_enough_members.description')}
				/>
			) : ''}

			<Styled.Content ref={contentRef}>
				<Styled.MovingAvatar ref={movingAvatarRef}>
					<img src={community.avatar_url} alt={community.name} />
					<span></span>
				</Styled.MovingAvatar>
				<Styled.List $attestationsDisabled={!attestationsEnabled} $attestationsMinimized={!showAttestations}>
					{community.members.map((member, index) => (
						<Styled.ListItem
							key={member.id}
							$index={index}
						>
							<Styled.UserAvatar
								onPointerDown={e => startDraggingAvatar(e, member.id)}
								src={member.avatar_thumbnail_url}
								placeholder={getInitials(member.name)}
								width={theme.spacing[22]}
								height={theme.spacing[22]}
								$attested={showAttestations && attestedMemberIds.includes(member.id)}
							/>
							<Styled.UserDetails $attested={showAttestations && attestedMemberIds.includes(member.id)}>
								<span>{member.name}</span>
								<span>{ROLE_NAMES[member.role]}</span>
							</Styled.UserDetails>
							{permissionLevel >= 3 && areMembersAttested && <Progress value={Number(member.membership_level) * 100} />}
						</Styled.ListItem>
					))}
				</Styled.List>
				{attestationsEnabled && (
					showAttestations ? (
						<Attestations
							dragState={dragState}
							community={community}
							resetAttestations={resetAttestations}
							addAttestedMemberId={addAttestedMemberId}
							removeAttestedMemberId={removeAttestedMemberId}
							onClose={() => setShowAttestations(false)}
						/>
					) : (
						<Styled.MinimizedAttestations onClick={() => setShowAttestations(true)} >
							<span>Attestations</span>
							<ArrowUpIcon />
						</Styled.MinimizedAttestations>
					)
				)}
			</Styled.Content>
			{showTutorial && (
				<Tutorial
					onClose={() => setShowTutorial(false)}
					texts={[
						t('tutorials.attestations.step1'),
						t('tutorials.attestations.step2'),
						t('tutorials.attestations.step3'),
					]}
				/>
			)}
		</Styled.Container >
	);
}
