import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as XIcon } from '../../../icons/x.svg';
import * as Styled from './create.styles';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import { ReactComponent as QuestionIcon } from '../../../icons/question.svg';
import { storeNewPoll } from '../../../app/api';
import Spinner from '../../../components/common/spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../../components/common/input';
import { usePopup } from '../../../providers/PopupContextProvider';
import { POPUP_TYPE } from '../../../components/common/popup/constants';
import { useTranslation } from 'react-i18next';
import WeightingTooltip from '../../../components/tooltips/pollsWeighting';
import DurationTooltip from '../../../components/tooltips/pollsDuration';

const errorTextsByField = {
	all: 'You must fill out all of the required fields',
	duration: 'Duration must be greater than 0',
	weighting: 'Weighting must be between 0 and 100%',
}

const tooltips = {
	weighting: props => <WeightingTooltip {...props} />,
	duration: props => <DurationTooltip {...props} />,
}

export default function CreatePoll({ onClose }) {
	const { id } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { showPopup, closePopup } = usePopup();
	const [storing, setStoring] = useState(false);
	const [lastFocusedField, setLastFocusedField] = useState(null);
	const [tooltip, setTooltip] = useState(null);

	const [data, setData] = useState({
		question: '',
		options: ['', ''],
		duration: '',
		weighting: '',
		community_id: id,
	})

	const [error, setError] = useState(null);

	function updateOption(index, value) {
		const nextOptions = [...data.options];
		nextOptions[index] = value;
		setData({ ...data, options: nextOptions });
	}

	function updateData(key, value) {
		setData({ ...data, [key]: value });
	}

	function addOption() {
		const nextOptions = [...data.options, ''];
		setData({ ...data, options: nextOptions });
	}

	function removeOption(index) {
		const nextOptions = data.options.filter((_, i) => i !== index);
		setData({ ...data, options: nextOptions });
	}

	const store = useCallback(async () => {
		try {
			setStoring(true);
			let res = await storeNewPoll({
				...data,
				options: data.options.filter(option => option !== ''),
				weighting: data.weighting / 100
			});
			showPopup({
				title: t('messages.poll_created'),
				type: POPUP_TYPE.ALERT,
				lockUI: true,
				buttons: [
					{
						label: t('polls.go_to_poll'),
						onClick: () => {
							navigate(`/polls/${res.id}`);
							closePopup();
						},
					},
				]
			});
		} catch (error) {
			console.error(error);
		} finally {
			setStoring(false);
		}
	}, [data, showPopup, t, navigate, closePopup]);

	useEffect(function validate() {
		if (lastFocusedField) {
			if (lastFocusedField.startsWith('option')) {
				const index = Number(lastFocusedField.split('.')[1]);
				if (index < 2 && data.options[index] === '') {
					setError(errorTextsByField.all);
					return;
				}
			} else {
				if (data[lastFocusedField] === '') {
					setError(errorTextsByField.all);
					return;
				}
			}
		}
		if (data.duration !== '' && data.duration <= 0) {
			setError(errorTextsByField.duration);
			return;
		}
		if (data.weighting !== '' && (data.weighting < 0 || data.weighting > 100)) {
			setError(errorTextsByField.weighting);
			return;
		}
		setError('');
	}, [data, lastFocusedField]);

	const disableButton = useMemo(() => {
		return (
			error ||
			data.question === '' ||
			data.options[0] === '' ||
			data.options[1] === '' ||
			data.duration === '' ||
			data.weighting === ''
		);
	}, [data, error])

	return (
		<Styled.Container>
			{storing && <Spinner />}
			<Styled.Header>
				<div>
					<XIcon onClick={onClose} />
				</div>
				<div>
					<h1>{t('polls.create_poll')}</h1>
				</div>
			</Styled.Header>
			<Styled.QuestionInput
				type="text"
				placeholder={t('polls.poll_question')}
				value={data.question}
				onChange={e => updateData('question', e.target.value)}
				onBlur={() => setLastFocusedField('question')}
			/>
			<Styled.Options>
				{data.options.map((option, index) => (
					<Styled.Option
						key={index}
						type="text"
						placeholder={`${t('common.option')} ${index + 1}`}
						value={option}
						onChange={(event) => updateOption(index, event.target.value)}
					>
						<Input
							type="text"
							placeholder={`Option ${index + 1}`}
							value={option}
							onChange={(event) => updateOption(index, event.target.value)}
							onBlur={() => setLastFocusedField(`option.${index}`)}
						/>
						{index > 1 ? <TrashIcon onClick={() => removeOption(index)} /> : <div></div>}
					</Styled.Option>
				))}
				<Styled.AddOption onClick={addOption}>
					<PlusIcon />
					<span>{t('polls.add_another')}</span>
				</Styled.AddOption>
			</Styled.Options>
			<Styled.Settings>
				<h2>{t('common.settings')}</h2>
				<div>
					<div>
					<span>{t('polls.duration_in_hours')}</span>
						<QuestionIcon onClick={() => setTooltip('duration')} />
					</div>
					<Styled.SettingsInput
						type="number"
						placeholder="2h"
						value={data.duration}
						onChange={e => updateData('duration', e.target.value)}
						hasError={error === errorTextsByField.duration}
						onBlur={() => setLastFocusedField('duration')}
					/>
				</div>
				<div>
					<div>
						<span>{t('polls.weighting_of_membership_levels')}</span>
						<span>({t('polls.between_1_and_100')})</span>
						<QuestionIcon onClick={() => setTooltip('weighting')} />
					</div>
					<Styled.SettingsInput
						type="number"
						placeholder="50%"
						value={data.weighting}
						onChange={e => updateData('weighting', e.target.value)}
						hasError={error === errorTextsByField.weighting}
						onBlur={() => setLastFocusedField('weighting')}
					/>
				</div>
			</Styled.Settings>
			{tooltip && tooltips[tooltip]({
				onClose: () => setTooltip(null)
			})}
			<Styled.Footer isOpen>
				<div className='buttons'>
					<button onClick={store} disabled={disableButton}>{t('polls.create_poll')}</button>
				</div>
				{error && <p className='error'>{error}</p>}
			</Styled.Footer>
		</Styled.Container>
	)
}
