import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: ${({ $on }) => $on ? 'flex-end' : 'flex-start'};
	padding: ${({ $width }) => `calc(${$width} / 12)`};
	width: ${({ $width }) => $width};
	background-color: ${({ theme, $on }) => $on ? theme.colors.white25 : theme.colors.white10};
	border-radius: 100px;
	
	> div {
		width: ${({ $width }) => `calc(${$width} / 3)`};	
		height: ${({ $width }) => `calc(${$width} / 3)`};
		border-radius: 100px;
		background-color: ${({ theme, $on }) => $on ? theme.colors.lime50 : theme.colors.white50};
	}
`;
