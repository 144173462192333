import React from 'react';
import { Triangle } from 'react-loader-spinner';
import * as Styled from './spinner.styles';

export default function Spinner() {
	return (
		<Styled.Dropback>
			<Triangle
				visible={true}
				height="80"
				width="80"
				color="#4fa94d"
				ariaLabel="triangle-loading"
				wrapperStyle={{}}
				wrapperClass="spinner"
			/>
		</Styled.Dropback>
	)
}
