import { POPUP_TYPE } from "../common/popup/constants";
import * as Styled from './styles';
import { ReactComponent as CloseIcon } from '../../icons/x-small.svg';
import { useTranslation } from "react-i18next";

export default function DurationTooltip({ onClose }) {
	const { t } = useTranslation();
	return (
		<Styled.Container
			isOpen={true}
			type={POPUP_TYPE.ALERT}
			lockUI
			onClickOutside={onClose}
		>
			<CloseIcon onClick={onClose} />
			<h3>{t('tooltips.polls_duration.title')}</h3>
			<p>{t('tooltips.duration.description')}</p>
		</Styled.Container>
	)
}

