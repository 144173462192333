import styled from 'styled-components';


export const Container = styled.div`
	width: ${({ theme, $width }) => $width || theme.spacing[22]};
	height: ${({ theme, $height }) => $height || theme.spacing[22]};
	border-radius: 50%;
	border: 2px solid ${({ theme }) => theme.colors.white10};
	flex-shrink: 0;	

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		vertical-align: middle;
		border-radius: 50%;
	}

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background-color: ${({ theme }) => theme.colors.white05};
		border-radius: 50%;
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ $width, $height, theme }) => `calc( ${($height || $width || theme.spacing[22])} / 3 )`};
	}
`;

