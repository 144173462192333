import { forwardRef } from 'react';
import * as Styled from './styles';

const Avatar = forwardRef(function({ src, placeholder, alt = "avatar", width, height, className, onClick = () => { }, children, ...otherProps }, ref) {
	return (
		<Styled.Container $width={width} $height={height || width} className={className} onClick={onClick} {...otherProps} ref={ref}>
			{
				children ? children : (
					src ?
						<img src={src} alt={alt} draggable="false" /> :
						<span>{placeholder}</span>
				)
			}
		</Styled.Container>
	);
})

export default Avatar;

