import { useNavigate } from 'react-router-dom';
import * as Styled from './styles';
import { ReactComponent as BackIcon } from '../../../../icons/back.svg';
import { ReactComponent as Email } from '../../../../icons/email.svg';
import alephLogo from '../../../../icons/aleph.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { requestResetPasswordEmail } from '../../../../app/api';
import { isValidEmail } from '../../../../utils/helpers';
import Spinner from '../../../../components/common/spinner';

export default function RequestResetPassword() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [error, setError] = useState('');
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	async function send(e) {
		e.preventDefault();
		try {
			setLoading(true);
			await requestResetPasswordEmail(email);
			setShowConfirmation(true);
			setError('');
			setEmail('');
		} catch (e) {
			setError(e.message);
			return;
		} finally {
			setLoading(false);
		}
	}

	function goBack() {
		navigate(-1);
	}

	return (
		<Styled.Container>
			{(loading) && <Spinner />}
			<BackIcon onClick={goBack} />
			<Styled.Headling>{t("auth.community_graphs")} </Styled.Headling>
			{!showConfirmation ? (
				<Styled.Form onSubmit={send}>
					<Styled.Hint>{t("auth.reset_password_enter_email")}</Styled.Hint>
					<Styled.EmailInput placeholder={t("common.email")} Icon={Email} value={email} onChange={e => setEmail(e.target.value)} />
					<Styled.ErrorText $hidden={!Boolean(error)}>{error}</Styled.ErrorText>
					<Styled.Button disabled={!isValidEmail(email)}>{t('common.continue')}</Styled.Button>
					<Styled.LearnMore href="https://www.maitri.network/communitygraphs" target="_blank">
						{t("common.learn_more")}
					</Styled.LearnMore>
				</Styled.Form>
			) : (
				<Styled.Confirmation>
					<p><Trans i18nKey="auth.reset_password_confirmation.text1" components={{ br: <br /> }} /></p>
					<p><Trans i18nKey="auth.reset_password_confirmation.text2" components={{ br: <br /> }} /></p>
					<button onClick={send}>{t("auth.resend_email")}</button>
				</Styled.Confirmation>
			)}
		</Styled.Container>
	);
}

