import { getGroupElements } from "../../../../utils/helpers";
import {
	INITIAL_CIRCLE_RADIUS,
	MAXIMUM_LINE_LENGTH_MULTIPLIER,
	MAX_ZOOM,
	USER_GROUP_TEXT_OFFSET, USER_GROUP_ANIMATION_DURATION, USER_GROUP_ANIMTION_DELAY
} from "./constants";
import {util} from "fabric";

export function calculateScoreScaleFactor(canvas, members) {
	const highestAttestation = getHighestAttestationScore(members);
	return (canvas.width - INITIAL_CIRCLE_RADIUS) / highestAttestation;
}

export function getHighestAttestationScore(members) {
	let highestAttestation = 0;
	members.forEach(member => {
		highestAttestation = Math.max(highestAttestation, member.attestation.score);
	});

	return highestAttestation || 100;
}

export function calculateNewAttestations(members, canvas) {
	let res = [];
	const sum = getGroupElements(canvas, 'userGroup').reduce((acc, group) => acc + group.left, 0);
	members.forEach(member => {
		const group = getGroupElements(canvas, 'userGroup').find(group => group.memberId === member.id);
		if (group) {
			res.push({
				...member.attestation,
				score: group.left / sum * 100,
			});
		} else {
			res.push({
				...member.attestation,
				score: 0,
			});
		}
	});
	return res;
}

export function zoom(delta, offsetX, state) {
	var zoom = this.getZoom();
	zoom *= 0.999 ** delta;

	if (zoom > MAX_ZOOM) zoom = MAX_ZOOM;
	if (zoom < 1) zoom = 1;

	this.zoomToPoint({ x: offsetX, y: this.height / 2 }, zoom);

	var vpt = this.viewportTransform;
	if (vpt[4] >= 0) {
		vpt[4] = 0;
	} else if (vpt[4] < this.getWidth() - this.getWidth() * zoom) {
		vpt[4] = this.getWidth() - this.getWidth() * zoom;
	}

	state.zoom = zoom;

	getGroupElements(this, 'userGroup').forEach(userGroup => {
		userGroup.scaleX = 1 / zoom;
		userGroup.scaleY = 1 / zoom;
		userGroup.top = getUserGroupTop(state);
		userGroup.setCoords();
	});
}

export function moveObject(canvas, obj, state) {
	const line = canvas.getObjects('line').find(obj => obj.name === 'attestationLine');
	if (obj.left < line.x1) {
		obj.set({ left: line.x1 })
	}
	if (obj.left >= line.x2) {
		// if (state.circleRadius > INITIAL_CIRCLE_RADIUS / MAXIMUM_LINE_LENGTH_MULTIPLIER) {
			let scale = canvas.width / (canvas.width + 1);
			// state.circleRadius *= scale;
			// const finalScale = 1 / canvas.getZoom() * state.circleRadius / INITIAL_CIRCLE_RADIUS;

			obj.set({
				left: line.x2,
				// scaleX: finalScale,
				// scaleY: finalScale,
			});

			getGroupElements(canvas, 'userGroup').forEach(userGroup => {
				if (userGroup !== obj) {
					userGroup.set({
						left: userGroup.left * scale,
						// scaleX: finalScale,
						// scaleY: finalScale,
					});
					userGroup.setCoords();
				}
			});
		// } else {
		// 	obj.left = line.x2;
		// }
	}
	obj.setCoords();
	canvas.renderAll();
}

export function fillWholeLine(canvas, state) {
	const line = canvas.getObjects('line').find(obj => obj.name === 'attestationLine');
	const userGroups = getGroupElements(canvas, 'userGroup');
	let maxLeft = 0;
	userGroups.forEach(group => {
		if (group.left > maxLeft) {
			maxLeft = group.left;
		}
	});
	if (line && maxLeft < line.x2) {
		const factor = line.x2 / maxLeft;
		state.animationState = 1;
		clearTimeout(state.animationTimeout);
		state.animationTimeout = setTimeout(() => {
			if (state.animationState !== 1) return;
			userGroups.forEach(group => {
				const left = Math.min(group.left * factor, line.x2);
				group.animate({
					left,
				}, {
					easing: util.ease.easeInOutCubic,
					duration: USER_GROUP_ANIMATION_DURATION,
					onChange: () => canvas.requestRenderAll(),
					onComplete: () => {
						state.animationState = 2;
					},
				});
			});
			state.animationState = 2;
		}, USER_GROUP_ANIMTION_DELAY);
	}
}

export function getUserGroupTop(state) {
	return state.centerY + USER_GROUP_TEXT_OFFSET / state.zoom * 2
}

export function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function panToMember(canvas, memberId, state) {
	const group = getGroupElements(canvas, 'userGroup').find(group => group.memberId === memberId);
	if (group) {
		const zoom = canvas.getZoom();

		// Calculate desired pan position
		let desiredPanX = -group.left * zoom + canvas.width / 2;

		// Calculate pan boundaries to avoid moving the canvas off screen
		const maxPanX = 0; // Left edge of the canvas
		const minPanX = -(canvas.width * zoom - canvas.getWidth()); // Right edge of the canvas

		// Clamp the desired pan position within boundaries
		desiredPanX = Math.max(Math.min(desiredPanX, maxPanX), minPanX);

		// Set the pan position
		canvas.viewportTransform[4] = desiredPanX;

		// Set the top position of the group
		canvas.setActiveObject(group);
		canvas.requestRenderAll();
	}
}