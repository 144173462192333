import styled from 'styled-components';

export const Container = styled.div`
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[8]}`};
`;

export const Tabs = styled.div`
	margin-top: ${({ theme }) => theme.spacing[7]};
	display: flex;
	gap: ${({ theme }) => theme.spacing[3]};
`;

export const Tab = styled.div`
	padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[5]}`};	
	border-radius: 100px;
	font-size: ${({ theme }) => theme.spacing[4]};
	font-weight: 500;
	background-color: ${({ theme, $active }) => $active ? theme.colors.white : theme.colors.white10};
	color: ${({ theme, $active }) => $active ? theme.colors.black : theme.colors.white50};
	cursor: pointer;
`;

export const Header = styled.h1`
	text-transform: capitalize;
	font-size: ${({ theme }) => theme.spacing[10]};
	font-weight: 300;
	color: ${({ theme }) => theme.colors.white};
`;


export const List = styled.ul`
	display: flex;
	flex-direction: column;
	
	li:not(:first-child) {
		border-top: 1px solid ${({ theme }) => theme.colors.white10};
	}
`;

export const Pending = styled.h3`
	margin-top: ${({ theme }) => theme.spacing[11]};
	text-transform: capitalize;
	font-size: ${({ theme }) => theme.spacing[7]};
	font-weight: 300;
	color: ${({ theme }) => theme.colors.white};
`;

export const Closed = styled.h3`
	margin-top: ${({ theme }) => theme.spacing[11]};
	text-transform: capitalize;
	font-size: ${({ theme }) => theme.spacing[7]};
	font-weight: 300;
	color: ${({ theme }) => theme.colors.white};
`;

export const EmptyNotifications = styled.p`
	margin-top: ${({ theme }) => theme.spacing[11]};
	font-size: ${({ theme }) => theme.spacing[4]};
	color: ${({ theme }) => theme.colors.white50};	
	text-align: center;
`;

