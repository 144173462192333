import * as Styled from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/common/spinner';
import Avatar from '../../../components/common/avatar';
import { useTheme } from 'styled-components';
import { getInitials } from '../../../utils/helpers';
import { getSpecificUsers } from '../../../app/api';
import { selectUser } from '../../user/userSlice';
import { selectUsers } from '../usersSlice';
import { useTranslation } from 'react-i18next';

export default function User() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const user = useSelector(selectUser);
	const users = useSelector(selectUsers)
	const { id: userId } = useParams();
	const theme = useTheme();
	const [data, setData] = useState({
		name: '',
		handle: '',
		avatar_url: '',
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function fetchUser() {
			try {
				setLoading(true);
				const data = await getSpecificUsers([userId]);
				setData(data[0]);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		}
		let user = users.find(user => user.id === userId);
		if (user) {
			setData(user);
			return;
		}
		fetchUser();
	}, [userId, users]);

	return (
		<Styled.Container>
			{loading && <Spinner />}
			<Styled.Header>
				<div>
					<CloseIcon onClick={() => navigate(-1)} />
					{user.id === userId && <button onClick={() => navigate('/profile', { replace: true })}>{t('common.edit')}</button>}
				</div>
			</Styled.Header>
			<Styled.Content>
				<Avatar
					src={data.avatar_url}
					alt={data.name}
					placeholder={getInitials(data.name)}
					width={theme.spacing[40]}
				/>
				<Styled.Name>{data.name}</Styled.Name>
				<Styled.Handle>@{data.handle}</Styled.Handle>
			</Styled.Content>
		</Styled.Container>
	);
}

