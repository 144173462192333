import React from 'react';
import * as Styled from './input.styles';

export default function Input({ label, className, Icon, ...props }) {
	return (
		<Styled.Wrapper className={className} $hasIcon={Boolean(Icon)}>
			{label && <label>{label}</label>}
			{Icon && <Icon />}
			<input {...props} />
		</Styled.Wrapper>
	);
}
