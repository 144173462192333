export const NOTIFICATION_TYPE = {
	'INVITATION': 'invitation',
	'REQUEST': 'request',
	'GENERAL': 'general',
}

export const NOTIFICATION_STATUS = {
	'PENDING': 'pending',
	'ACCEPTED': 'accepted', 
	'REJECTED': 'rejected', 
	'UNREAD': 'unread', 
	'READ': 'read', 
}

