import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../components/common/spinner';
import { ReactComponent as ForwardIcon } from '../../../icons/forward.svg';
import { ReactComponent as BackIcon } from '../../../icons/back.svg';
import { ReactComponent as CubeIcon } from '../../../icons/cube.svg';
import { selectCommunitiesLoading, selectCommunityById, fetchCommunityPolls } from '../communitiesSlice';
import { format } from 'date-fns';
import * as Styled from './polls.styles';
import CreatePoll from './create';
import { useTranslation } from 'react-i18next';

function formatDatetime(date) {
	const d = new Date(date);
	return format(d, "MMMM d, h:mma").replace('AM', 'am').replace('PM', 'pm');
}

export default function Polls() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	let { id } = useParams();
	const community = useSelector(state => selectCommunityById(state, id));
	const [creating, setCreating] = useState(false);
	const [fetched, setFetched] = useState(false);

	useEffect(() => {
		if (community && !fetched) {
			dispatch(fetchCommunityPolls(community.id));
			setFetched(true);
		}
	}, [community, dispatch, fetched]);

	if (creating) {
		return <CreatePoll onClose={() => setCreating(false)} />;
	}

	return (
		<Styled.Container>
			{!fetched && <Spinner />}
			<Styled.Header>
				<div>
					<BackIcon onClick={() => navigate(`/communities/${id}`)} />
				</div>
				<div>
					<h1>{t('common.polls')}</h1>
					{(!community || community.polls.length > 0) && (
						<button onClick={() => setCreating(true)}>{t('common.create_new')}</button>
					)}
				</div>
			</Styled.Header>
			{community && community.polls.length ? (
				<Styled.List>
					{community.polls.map(poll => (
						<Styled.ListItem key={poll.id} >
							<div>
								<p>{poll.question}</p>
								<ForwardIcon onClick={() => navigate(`/polls/${poll.id}`)} />
							</div>
							<div>
								<div>
									<span>{t('polls.poll_created_by.unwrapped')}</span>
									<span>{poll.created_by_name}</span>
								</div>
								<div>
									<span>{t('polls.poll_started.unwrapped')}</span>
									<span>{formatDatetime(poll.start_time)}</span>
								</div>
								<div>
									<span>{t('polls.poll_ends.unwrapped')}</span>
									<span>{poll.time_remaining <= 0 ? t('common.closed').toUpperCase() : formatDatetime(poll.end_time)}</span>
								</div>
							</div>
						</Styled.ListItem>
					))}
				</Styled.List>
			) : (
				<Styled.Empty>
					<CubeIcon />
					<h2>{t('polls.no_poll_created_yet')}</h2>
					<button onClick={() => setCreating(true)}>{t('common.create_new')}</button>
				</Styled.Empty>
			)}
		</Styled.Container>
	)
}
