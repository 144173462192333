import styled from 'styled-components';
import Avatar from '../../components/common/avatar';

export const Container = styled.li`
	display: flex;
	gap: ${({ theme }) => theme.spacing[4]};
	padding: ${({ theme }) => `${theme.spacing[5]} 0`};
	${({ theme, index }) => `
		${index > 0 ? `border-top: 1px solid ${theme.colors.white10};` : ''}	
	`}
`;

export const UserAvatar = styled(Avatar)`
	flex-shrink: 0;
`;

export const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: ${({ theme }) => theme.spacing[3]};
	color: ${({ theme }) => theme.colors.white};

	p {
		font-size: ${({ theme }) => theme.spacing[4.5]};
		line-height: 1.2;
		color: ${({ theme }) => theme.colors.white80};
		
		span {
			color: ${({ theme }) => theme.colors.white};
			font-weight: 500;
			cursor: pointer;
		}
	}

	div {
		display: flex;
		gap: ${({ theme }) => theme.spacing[3]};

		button {
			padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
			border-radius: 100px;
			text-transform: capitalize;
			font-size: ${({ theme }) => theme.spacing[4]};
			font-weight: 500;
			border: none;
			cursor: pointer;
			
			&:first-child {
				background-color: ${({ theme }) => theme.colors.white};
				color: ${({ theme }) => theme.colors.black};
			}
			&:last-child {
				background-color: ${({ theme }) => theme.colors.white25};
				color: ${({ theme }) => theme.colors.white};
			}
		}
	}

`;

export const Status = styled.span`
	padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
	border-radius: 100px;
	text-transform: capitalize;
	font-size: ${({ theme }) => theme.spacing[3]};
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme, $accepted }) => $accepted ? theme.colors.lime25 : theme.colors.magenta25};
`;

