import * as Styled from './styles';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../icons/back.svg';
import Input from '../../../components/common/input';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setUser } from '../userSlice';
import { getAuthData, updateProfile } from '../../../app/api';
import { usePopup } from '../../../providers/PopupContextProvider';
import { POPUP_TYPE } from '../../../components/common/popup/constants';
import Spinner from '../../../components/common/spinner';
import Avatar from '../../../components/common/avatar';
import { useTheme } from 'styled-components';
import { getInitials } from '../../../utils/helpers';
import CropBox from '../../../components/common/cropper';
import { MAX_FILE_SIZE } from '../constants';
import { useTranslation } from 'react-i18next';

export default function Profile() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const { t } = useTranslation();
	const user = useSelector(selectUser);
	const isNewUser = !user.is_profile_complete;
	const [data, setData] = useState({
		name: '',
		handle: '',
		avatar: '',
	});
	const [showCropBox, setShowCropBox] = useState(false);
	const [avatarUrl, setAvatarUrl] = useState(user.avatar_url);
	const [cropImage, setCropImage] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const inputRef = useRef(null);
	const { showPopup, closePopup } = usePopup();

	async function save() {
		if (data.avatar && data.avatar.size > MAX_FILE_SIZE) {
			setError('File size is bigger than 1MB');
			return;
		}
		if (!/^[a-z0-9-]+$/.test(data.handle)) {
			setError(t('errors.handle_restriction'));
			return;
		}
		setLoading(true);
		try {
			await updateProfile(data);
			dispatch(setUser({
				...user,
				...data,
				avatar_url: avatarUrl,
				is_profile_complete: true,
			}));
			if (isNewUser) {
				navigate('/');
			} else {
				showPopup({
					title: t('messages.profile_successfully_updated'),
					type: POPUP_TYPE.ALERT,
					lockUI: true,
					buttons: [
						{
							label: t('common.close'),
							onClick: () => {
								closePopup();
								navigate(`/users/${user.id}`, { replace: true })
							},
						},
					]
				});
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	}

	function updateData(key, value) {
		setData({ ...data, [key]: value });
		setError(null);
	}

	function onUpload(event) {
		const file = event.target.files[0];
		const url = URL.createObjectURL(file);
		setCropImage(url);
		setShowCropBox(true);
	}

	function onCrop(blob) {
		if (blob.size > MAX_FILE_SIZE) {
			setError('File size is bigger than 1MB');
			return;
		}
		setError(null);
		setData({ ...data, avatar: blob });
		setAvatarUrl(URL.createObjectURL(blob));
		setShowCropBox(false);
	}

	const canSave = useMemo(() => {
		if (error) return false;
		if (data.name === '' || data.handle === '') return false;
		if (!isNewUser && data.name === user.name && data.handle === user?.handle && avatarUrl === user?.avatar_url) return false;
		return true;
	}, [error, data.name, data.handle, isNewUser, user.name, user?.handle, user?.avatar_url, avatarUrl]);

	useEffect(() => {
		if (!user.is_profile_complete) {
			return;
		}
		setData({
			name: user.name,
			handle: user.handle,
		});
		setAvatarUrl(user.avatar_url);
	}, [user]);

	return (
		<Styled.Container>
			{loading && <Spinner />}
			{showCropBox && <CropBox src={cropImage} onCrop={onCrop} onCancel={() => setShowCropBox(false)} />}
			{!isNewUser &&
				<Styled.Header>
					<div>
						<BackIcon onClick={() => navigate(-1)} />
					</div>
				</Styled.Header>
			}
			<Styled.AvatarUpload onClick={() => inputRef.current.click()}>
				<Avatar
					src={avatarUrl}
					alt={data.name}
					placeholder={getInitials(data.name) || ''}
					width={theme.spacing[40]}
				/>
				<span >{t('common.set_new_photo')} &nbsp;(&lt; 1{t('common.mb')})</span>
				<input type="file" ref={inputRef} onChange={onUpload} />
			</Styled.AvatarUpload>
			<Styled.InputsWrapper>
				<Input placeholder={t('user.name')} label={t('user.name')} value={data.name} onChange={e => updateData('name', e.target.value)} />
				<Input placeholder={t('user.handle')} label={t('user.handle')} value={data.handle} onChange={e => updateData('handle', e.target.value)} autocapitalize="off" />
			</Styled.InputsWrapper>
			{isNewUser && (
				<Styled.Instruction>
					<h5>Set up your Profile</h5>
					<p>Enter name, unique handle, and profile image (optional). You will be able to edit these later.</p>
				</Styled.Instruction>
			)}
			<Styled.Footer isOpen={true}>
				<div className='buttons'>
					<button onClick={save} disabled={!canSave}>{isNewUser ? t('common.continue') : t('common.save')}</button>
				</div>
				{error && <p className='error'>{error}</p>}
			</Styled.Footer>
		</Styled.Container>
	);
}

