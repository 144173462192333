import {Circle} from "fabric";
import {INITIAL_CIRCLE_RADIUS} from "./constants";
import {delay} from "./helpers";

export async function createRipples(canvas, circlesCount, xPos, yPos) {
	const scale = canvas.viewportTransform[0];
	for(let i = 0; i < circlesCount; i++) {
		const ripple = new Circle({
			radius: INITIAL_CIRCLE_RADIUS / scale,
			stroke: 'white',
			strokeWidth: 2 / scale,
			fill: 'rgba(0,0,0,0)',
			left: xPos,
			top: yPos,
			opacity: 0.5,
			selectable: false,
			originX: "center",
			originY: "center"
		});
		canvas.add(ripple);
		ripple.animate({ scaleX: 2.5, scaleY: 2.5, opacity: 0 },
			{
				duration: 600,
				onChange: () => canvas.requestRenderAll(),
				onComplete: function () {
					canvas.remove(ripple);
				},
			});
		await delay(200);
	}
}