import ReactSlider from 'react-slider';
import styled from 'styled-components';
import Avatar from '../../../components/common/avatar';

export const Container = styled.div`
	background: rgb(85,2,32);
	background: #111113;
	position: relative;
	
	>svg:first-of-type {
		width: ${({ theme }) => theme.spacing[7]};
		height: ${({ theme }) => theme.spacing[7]};
		position: absolute;
		right: ${({ theme }) => theme.spacing[7]};
		top: ${({ theme }) => theme.spacing[2.5]};
		cursor: pointer;
	}
	
	.pan-cursor {
		cursor: e-resize !important;
    }

	h1 {
		text-transform: capitalize;
        font-size: ${({ theme }) => theme.spacing[4.5]};
        font-weight: 500;
        color: ${({ theme }) => theme.colors.white};
		text-align: center;
		padding-top: 10px;
		position: absolute;
		top:10px;
		left: 0;
		width: 100%;
        user-select: none;
    }
`;
export const List = styled.ul`
	margin-top: ${({ theme }) => theme.spacing[5]};
	display: flex;
	flex-direction: column;
`;

export const ListItem = styled.li`
	position: relative;
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[4]};
	cursor: pointer;
	padding: ${({ theme }) => `${theme.spacing[5]} 0`};
	${({ theme, $index }) => `
		${$index > 0 ? `border-top: 1px solid ${theme.colors.white10};` : ''}	
	`}
`;

export const UserAvatar = styled(Avatar)`
	flex-shrink: 0;
`;

export const UserDetails = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-grow: 1;
	flex-shrink: 0;
	gap: ${({ theme }) => theme.spacing[3]};
	color: ${({ theme }) => theme.colors.white};

	>span {
		font-size: ${({ theme }) => theme.spacing[5]};
		font-weight: 600;
	}

	>div {
		display: flex;
		justify-content: space-between;
		width: 100%;
		>span {
			font-size: ${({ theme }) => theme.spacing[4]};
			color: ${({ theme }) => theme.colors.white50};
		}
	}

`;

export const AttestButton = styled.button`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	padding: ${({ theme }) => `${theme.spacing[2.5]} ${theme.spacing[5.5]}`};	
	text-transform: capitalize;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.white10};
	border-radius: 100px;
	border: none;
	font-size: ${({ theme }) => theme.spacing[4]};
	font-weight: 500;
`;

