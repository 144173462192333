import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[8]}`};
	height: 100%;
`;

export const Header = styled.div`
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing[7]};
		
		>div:first-child {
			svg {
				background-color: ${({ theme }) => theme.colors.white10};	
				padding: ${({ theme }) => theme.spacing[1]};
				border-radius: ${({ theme }) => theme.spacing[3]};
				cursor: pointer;
				
				path {
					fill: ${({ theme }) => theme.colors.white};
				}
			}

		}

		>div:last-child {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: ${({ theme }) => theme.spacing[2]};
			
			h1 {
				text-transform: capitalize;
				font-size: ${({ theme }) => theme.spacing[11]};
				font-weight: 300;
				color: ${({ theme }) => theme.colors.white};
			}
			
			button {
				text-transform: capitalize;
				background-color: ${({ theme }) => theme.colors.white};	
				color: ${({ theme }) => theme.colors.black};
				padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[4]}`};
				border-radius: 100px;
				cursor: pointer;
				border: none;
			}
		}		
`;

export const List = styled.ul`
	margin-top: ${({ theme }) => theme.spacing[11]};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[10]};
`;

export const ListItem = styled.li`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[0.5]};
	
	>div:first-child {
		display: flex;
		align-items: center;
		padding: ${({ theme }) => theme.spacing[4]};
		background-color: ${({ theme }) => theme.colors.white10};
		border-radius: 4px 4px 2px 2px;

		p {
			font-size: ${({ theme }) => theme.spacing[4.5]};
			color: ${({ theme }) => theme.colors.white};
			font-weight: 500;
			flex-grow: 1;
		}


		svg {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: ${({ theme }) => theme.colors.white};
			width: ${({ theme }) => theme.spacing[6]};
			height: ${({ theme }) => theme.spacing[6]};
			padding: ${({ theme }) => theme.spacing[1.5]};
			border: none;
			border-radius: ${({ theme }) => theme.spacing[3]};
			cursor: pointer;
		}
	}

	>div:last-child {
		display: flex;
		gap: ${({ theme }) => theme.spacing[0.5]};

		>div {
			min-width: 25%;
			padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[2]}`};
			display: flex;
			flex-direction: column;
			gap: ${({ theme }) => theme.spacing[2]};
			background-color: ${({ theme }) => theme.colors.white05};

			span:first-child {
				font-size: ${({ theme }) => theme.spacing[3.5]};
				color: ${({ theme }) => theme.colors.white50};
			}
			span:last-child {
				font-size: ${({ theme }) => theme.spacing[4]};
				color: ${({ theme }) => theme.colors.white};
			}

			&:nth-child(1) {
				border-radius: 2px 2px 2px 4px;
			}
			&:nth-child(2) {
				border-radius: 2px;	
				flex-grow: 1;
			}
			&:nth-child(3) {
				border-radius: 2px 2px 4px 2px;	
				flex-grow: 1;
			}
		}
	
	}
`;

export const Empty = styled.div`
    display: flex;
	justify-content: center;
    flex-direction: column;
    align-items: center;
	flex-grow: 1;
	
	img {
		width: ${({ theme }) => theme.spacing[12]};
	}
	
	h2 {
		margin-top: ${({ theme }) => theme.spacing[5]};
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[7]};
		font-weight: 500;
	}

	button {
		margin-top: ${({ theme }) => theme.spacing[10]};
		background-color: ${({ theme }) => theme.colors.white};	
		color: ${({ theme }) => theme.colors.black};
		width: 100%;
		padding: ${({ theme }) => theme.spacing[4]};
		border-radius: 100px;
		cursor: pointer;
		border: none;
	}
`;



