export const theme = {
	spacing: {
		px: '1px', 0: '0',
		0.5: '0.125rem',
		1: '0.25rem',
		1.5: '0.375rem',
		2: '0.5rem',
		2.5: '0.625rem',
		3: '0.75rem',
		3.5: '0.875rem',
		4: '1rem',
		4.5: '1.125rem',
		5: '1.25rem',
		5.5: '1.375rem',
		6: '1.5rem',
		6.5: '1.625rem',
		7: '1.75rem',
		7.5: '1.875rem',
		8: '2rem',
		9: '2.25rem',
		10: '2.5rem',
		11: '2.75rem',
		12: '3rem',
		13: '3.25rem',
		14: '3.5rem',
		15: '3.75rem',
		16: '4rem',
		18: '4.5rem',
		20: '5rem',
		21: '5.25rem',
		22: '5.5rem',
		24: '6rem',
		28: '7rem',
		30: '7.5rem',
		32: '8rem',
		36: '9rem',
		40: '10rem',
		44: '11rem',
		48: '12rem',
		52: '13rem',
		56: '14rem',
		60: '15rem',
		64: '16rem',
		72: '18rem',
		80: '20rem',
		96: '24rem',
		112: '28rem',
		120: '30rem',
	},
	screens: {
		'sm': '640px',
		'md': '768px',
		'lg': '1024px',
		'xl': '1280px',
		'2xl': '1536px',
	},
	colors: {
		'transparent': 'transparent',
		'black': '#000',
		'white': '#fff',
		'white80': 'rgba(255, 255, 255, 0.8)',
		'white50': 'rgba(255, 255, 255, 0.5)',
		'white25': 'rgba(255, 255, 255, 0.25)',
		'white15': 'rgba(255, 255, 255, 0.15)',
		'white10': 'rgba(255, 255, 255, 0.1)',
		'white05': 'rgba(255, 255, 255, 0.05)',
		'lime': '#31EE4F',
		'lime75': 'rgba(49, 238, 79, 0.75)',
		'lime50': 'rgba(49, 238, 79, 0.5)',
		'lime25': 'rgba(49, 238, 79, 0.25)',
		'lime10': 'rgba(49, 238, 79, 0.1)',
		'neon': '#BDFF00',
		'magenta': 'rgba(255, 0, 0, 1)',
		'magenta10': 'rgba(255, 0, 0, 0.1)',
		'magenta25': 'rgba(255, 0, 0, 0.25)',
		'magenta50': 'rgba(255, 0, 0, 0.5)',
		'magenta75': 'rgba(255, 0, 0, 0.75)',
		'midnight': '#121063',
		'errorRed': '#FF4C40',
		'cyan': '#00C8DF',
		'pink10': 'rgba(255, 13, 117, 0.1)',
		'purplePrimary': '#7D45F7',
		'metal': '#565584',
		'tahiti': '#3ab7bf',
		'silver': '#ecebff',
		'bubble-gum': '#ff77e9',
		'bermuda': '#78dcca',
		'digitalBlack': '#111113',
		'darkGray': '#0C0C0D',
		'lightGray': 'rgb(17, 17, 17)',
		'lightGray25': 'rgba(17, 17, 17, 0.25)',
		gray: {
			50: '#f9fafb',
			100: '#f3f4f6',
			200: '#e5e7eb',
			300: '#d1d5db',
			400: '#9ca3af',
			500: '#6b7280',
			600: '#4b5563',
			700: '#374151',
			800: '#1f2937',
			900: '#111827',
		},
		red: {
			50: '#fef2f2',
			100: '#fee2e2',
			200: '#fecaca',
			300: '#fca5a5',
			400: '#f87171',
			500: '#ef4444',
			600: '#dc2626',
			700: '#b91c1c',
			800: '#991b1b',
			900: '#7f1d1d',
		},
		yellow: {
			50: '#fffbeb',
			100: '#fef3c7',
			200: '#fde68a',
			300: '#fcd34d',
			400: '#fbbf24',
			500: '#f59e0b',
			600: '#d97706',
			700: '#b45309',
			800: '#92400e',
			900: '#78350f',
		},
		green: {
			50: '#ecfdf5',
			100: '#d1fae5',
			200: '#a7f3d0',
			300: '#6ee7b7',
			400: '#34d399',
			500: '#10b981',
			600: '#059669',
			700: '#047857',
			800: '#065f46',
			900: '#064e3b',
		},
		blue: {
			50: '#eff6ff',
			100: '#dbeafe',
			200: '#bfdbfe',
			300: '#93c5fd',
			400: '#60a5fa',
			500: '#3b82f6',
			600: '#2563eb',
			700: '#1d4ed8',
			800: '#1e40af',
			900: '#1e3a8a',
		},
		indigo: {
			50: '#eef2ff',
			100: '#e0e7ff',
			200: '#c7d2fe',
			300: '#a5b4fc',
			400: '#818cf8',
			500: '#6366f1',
			600: '#4f46e5',
			700: '#4338ca',
			800: '#3730a3',
			900: '#312e81',
		},
		purple: {
			50: '#f5f3ff',
			100: '#ede9fe',
			200: '#ddd6fe',
			300: '#c4b5fd',
			400: '#a78bfa',
			500: '#8b5cf6',
			600: '#7c3aed',
			700: '#6d28d9',
			800: '#5b21b6',
			900: '#4c1d95',
		},
		pink: {
			50: '#fdf2f8',
			100: '#fce7f3',
			200: '#fbcfe8',
			300: '#f9a8d4',
			400: '#f472b6',
			500: '#ec4899',
			600: '#db2777',
			700: '#be185d',
			800: '#9d174d',
			900: '#831843',
		},
	},
};

