import axios from 'axios';
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE } from '../features/notifications/constants';
import * as Sentry from '@sentry/react';

const API_URL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
});

apiClient.interceptors.request.use(
	config => {
		// You can add common headers here, e.g., Authorization
		// const token = localStorage.getItem('token');
		// if (token) {
		//   config.headers.Authorization = `Bearer ${token}`;
		// }

		//set cookie
		// if (!document.cookie) {
		// 	config.headers.Cookie = 'connect.sid=s%3AizhuxgL4nfqmVpTsahk3cKByERrSyv60.3%2FZRhqEUB6gq56htRey2psFu5kzSKsDiKNJFrpi46iQ';
		// }
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

// Add a response interceptor if needed
apiClient.interceptors.response.use(
	response => response,
	error => {
		// Handle error responses, e.g., logging out on 401 Unauthorized
		const pathName = window.location.pathname;
		if (!pathName.startsWith('/login') && !pathName.startsWith('/signup') && error.response && error.response.status === 401) {
			window.location.href = '/login';
		}
		if (!error.response || error.response.status !== 401) {
			Sentry.captureException(error);
		}
		return Promise.reject(error);
	}
);

export async function authenticateUser(email, password) {
	try {
		const response = await apiClient.post('/user/auth', {
			email,
			password,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
};

export async function getAuthData() {
	try {
		const response = await apiClient.get('/user/me');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.errors || error);
	}
}

export async function logoutUser() {
	try {
		apiClient.post('/user/logout');
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getZupaassNonce() {
	try {
		const response = await apiClient.get('/user/getZupassNonce', {
			responseType: 'text',
		})
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}

}

export async function authWithZupass(pcdStr) {
	try {
		const response = await apiClient.post('/user/authWithZupass', pcdStr,
			{
				headers: {
					mode: "cors",
					"access-control-allow-origin": "*",
				},
			});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function verifyWithZupass(pcdStr) {
	try {
		const response = await apiClient.post('/user/addUserVerificationWithZupass', pcdStr);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function requestRegistrationEmail(email) {
	try {
		const response = await apiClient.post('/user/requestRegistrationEmail', {
			email,
		});
		return response.data;
	} catch (error) {
		let errors = error.response.data?.errors;
		if (errors) {
			throw new Error(errors[0].msg);
		}
		throw new Error(error.response.data?.error || error.response.data || error);
	}
}

export async function requestVerificationWithEmail(email, communityId) {
	try {
		const response = await apiClient.post('user/requestAttendanceVerificationEmail', {
			email,
			community_id: communityId,
		});
		return response.data;
	} catch (error) {
		let errors = error.response.data?.errors;
		if (errors) {
			throw new Error(errors[0].msg);
		}
		throw new Error(error.response.data?.error || error.response.data || error);
	}
}

export async function verifyWithEmail(email, hash) {
	try {
		const response = await apiClient.post('user/addUserVerificationWithEmail', {
			email,
			hash,
		});
		return response.data;
	} catch (error) {
		let errors = error.response.data?.errors;
		if (errors) {
			throw new Error(errors[0].msg);
		}
		throw new Error(error.response.data?.error || error.response.data || error);
	}
}

export async function requestResetPasswordEmail(email) {
	try {
		const response = await apiClient.post('/user/requestPasswordResetEmail', {
			email,
		});
		return response.data;
	} catch (error) {
		let errors = error.response.data?.errors;
		if (errors) {
			throw new Error(errors[0].msg);
		}
		throw new Error(error.response.data?.error || error.response.data || error);
	}
}

export async function registerWithHash(email, password, hash) {
	try {
		const response = await apiClient.post('/user/registerWithHash', {
			email,
			password,
			hash,
		});
		return response.data;
	} catch (error) {
		let errors = error.response.data?.errors;
		if (errors) {
			throw new Error(errors[0].msg);
		}
		throw new Error(error.response.data?.error || error);
	}
}

export async function resetPasswordWithHash(email, password, hash) {
	try {
		const response = await apiClient.post('/user/resetPasswordWithHash', {
			email,
			password,
			hash,
		});
		return response.data;
	} catch (error) {
		let errors = error.response.data?.errors;
		if (errors) {
			throw new Error(errors[0].msg);
		}
		throw new Error(error.response.data?.error || error);
	}
}

export async function getUserVerifications() {
	try {
		const response = await apiClient.get('/user/getUserVerifications');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getVerifiedAndJoinedCommunities() {
	try {
		const response = await apiClient.get('/user/getVerifiedAndJoinedCommunities');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getAllCommunities(eventId) {
	try {
		const queryString = eventId ? `?event_id=${eventId}` : '';
		const response = await apiClient.get(`/community/getAll${queryString}`);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
};

export async function getCommunity(communityId) {
	try {
		const response = await apiClient.get(`/community/getById?community_id=${communityId}`);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getCommunityMembers(communityId) {
	try {
		const response = await apiClient.get(`/community/getMembers?community_id=${communityId}`);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getCommunityPolls(communityId) {
	try {
		const response = await apiClient.get(`/polls/getByCommunityId?community_id=${communityId}`);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function createCommunity(communityData) {
	try {
		const formData = new FormData();
		for (const key in communityData) {
			if (key === 'avatar') {
				if (!communityData[key]) {
					continue;
				}
				if (!(communityData[key] instanceof Blob)) {
					throw new Error('Invalid file');
				}
				formData.append('avatar', communityData[key], 'avatar.jpg');
			} else {
				if (communityData[key] != null) {
					formData.append(key, communityData[key]);
				}
			}
		}
		const response = await apiClient.post('/community/create', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function updateCommunity(communityData) {
	try {
		const formData = new FormData();
		for (const key in communityData) {
			if (key === 'avatar') {
				if (!communityData[key]) {
					continue;
				}
				if (!(communityData[key] instanceof Blob)) {
					throw new Error('Invalid file');
				}
				formData.append('avatar', communityData[key], 'avatar.jpg');
			} else {
				if (communityData[key] != null) {
					formData.append(key, communityData[key]);
				}
			}
		}
		const response = await apiClient.post('/community/update', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function deleteCommunity(communityId) {
	try {
		const response = await apiClient.post(`/community/delete`, {
			community_id: communityId,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function joinToCommunity(communityId) {
	try {
		const response = await apiClient.post(`/community/join`, {
			community_id: communityId,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}


export async function getPoll(pollId) {
	try {
		const response = await apiClient.get(`/polls/getById/${pollId}`);
		const poll = response.data.poll
		poll.options = response.data.options;
		poll.community_vote_proportion = response.data.community_vote_proportion;
		return poll;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function storePollVote(pollId, optionNumber) {
	try {
		const response = await apiClient.post(`/polls/vote/${pollId}`, {
			option: optionNumber,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function storeNewPoll(pollData) {
	try {
		const response = await apiClient.post('/polls/create', pollData);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function deletePoll(pollId) {
	try {
		const response = await apiClient.delete(`/polls/${pollId}`);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getCommunityAttestations(communityId) {
	try {
		const response = await apiClient.get(`attestation/getMine?community_id=${communityId}`);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getAllUsers(eventId) {
	try {
		const queryString = eventId ? `?event_id=${eventId}` : '';
		const response = await apiClient.get(`/user/getAll${queryString}`);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getSpecificUsers(ids) {
	try {
		const response = await apiClient.post('/user/getSpecified', {
			ids,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function storeAttestations(attestations) {
	try {
		const response = await apiClient.post('attestation/setAttestations', {
			attestations,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getAllNotifications() {
	try {
		const response = await apiClient.get(`/invite/getMine`);
		response.data.invites.forEach(notification => {
			notification.type = NOTIFICATION_TYPE.INVITATION;
			notification.closed = false;
			notification.status = NOTIFICATION_STATUS.PENDING;
		});
		response.data.notifications.forEach(notification => {
			notification.status = notification.type === 'invite_rejected' ? NOTIFICATION_STATUS.REJECTED : NOTIFICATION_STATUS.ACCEPTED;
			notification.type = NOTIFICATION_TYPE.INVITATION;
			notification.closed = true;
		});
		response.data = [...response.data.invites, ...response.data.notifications]
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function getInvitedMembersInCommunity(communityId) {
	try {
		const response = await apiClient.get(`invite/getByCommunityId?community_id=${communityId}`);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function inviteUsers(communityId, userId) {
	try {
		const response = await apiClient.post('/invite/create', {
			community_id: communityId,
			invitee_id: userId,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function respondToInvitation(communityId, accepted) {
	try {
		const response = await apiClient.post(`/invite/respond/`, {
			community_id: communityId,
			is_accepted: accepted,
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export async function updateProfile(data) {
	try {
		const formData = new FormData();
		for (const key in data) {
			if (key === 'avatar') {
				if (!data[key]) {
					continue;
				}
				if (!(data[key] instanceof Blob)) {
					throw new Error('Invalid file');
				}
				formData.append('avatar', data[key], 'avatar.jpg');
			} else {
				formData.append(key, data[key]);
			}
		}
		const response = await apiClient.post('/user/updateProfile', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data?.error || error);
	}
}

export default apiClient;

