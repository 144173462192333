import styled from 'styled-components';

export const Container = styled.div`
	height: 100dvh;
	display: flex;
	flex-direction: column;
	background: rgb(85,2,32);
	background: linear-gradient(135deg, rgba(85,2,32,1) 0%, rgba(23,16,18,1) 25%, rgba(23,16,18,1) 25%, rgba(17,17,17,1) 100%);

	max-width: ${({theme}) => theme.screens.md};
	margin: 0 auto;
`;

export const Main = styled.div`
	flex-grow: 1;
	overflow-y: auto;
`;

export const Navigation = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[10]} ${theme.spacing[12]} ${theme.spacing[10]}`};
	background-color: ${({ theme }) => theme.colors.darkGray};
	border-top: 1px solid ${({ theme }) => theme.colors.white10};
	border-radius: 24px 24px 0px 0px;
	color: ${({ theme }) => theme.colors.white50};
	text-transform: capitalize;

	a {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: ${({ theme }) => theme.spacing[2]};
		text-decoration: none;
		color: inherit;
		
		&.active {
			color: ${({ theme }) => theme.colors.white};
		}

		svg {
			width: ${({ theme }) => theme.spacing[8]};
			height: ${({ theme }) => theme.spacing[8]};
		}
	}


	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: -1px;
		width: 100%;
		height: 1px;
		background: rgb(85,2,32);
		background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0, 0, 0, 0) 30%, rgba(255,255,255,0.7) 50% , rgba(0, 0, 0, 0) 70%, rgba(17,17,17,0) 100%);
	}
`;

