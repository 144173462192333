import React, { useState, useEffect, useCallback } from 'react';
import * as Styled from './styles';
import { ReactComponent as ArrowDown } from '../../../icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../icons/arrow-up.svg';

export default function Select({ options, onChange, className }) {
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedOption, setSelectedOption] = useState(options[0]);

	function selectOption(option) {
		setShowDropdown(false);
		if (option === selectedOption) return;
		setSelectedOption(option);
		onChange(option);
	}

	//hide dropdown on outside click
	const handleClickOutside = useCallback(event => {
		if (showDropdown && !event.target.closest(`${Styled.Container}`)) {
			setShowDropdown(false);
		}
	}, [showDropdown]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [handleClickOutside]);

	return (
		<Styled.Container className={className}>
			<Styled.Content
				$selected={selectedOption !== options[0]}
				onClick={() => showDropdown ? setShowDropdown(false) : setShowDropdown(true)}
			>
				{selectedOption.label} {showDropdown ? <ArrowUp /> : <ArrowDown />}
			</Styled.Content>
			{showDropdown &&
				<Styled.Options>
					{options.map((option, index) => (
						<Styled.Option key={index} onClick={() => selectOption(option)}>
							{option.label}
						</Styled.Option>
					))}
				</Styled.Options>
			}
		</Styled.Container>
	);
}
