import styled from 'styled-components';
import Popup from '../../../components/common/popup/popup';

export const Container = styled.div`
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[8]}`};
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;

	>div:first-child {
		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			padding: ${({ theme }) => theme.spacing[1]};
			border-radius: ${({ theme }) => theme.spacing[3]};
			cursor: pointer;
			
			path {
				fill: ${({ theme }) => theme.colors.white};
			}
		}
		
		button {
			padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
			border: none;
			border-radius: 100px;
			background-color: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.black};
			text-transform: capitalize;
			font-weight: 500;
		}
	}
`;

export const Content = styled.div`
	margin-top: ${({ theme }) => theme.spacing[10]};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Name = styled.h1`
	margin-top: ${({ theme }) => theme.spacing[4]};
	font-size: ${({ theme }) => theme.spacing[5.5]};
	font-weight: 500;
	color: ${({ theme }) => theme.colors.white};
`;

export const Handle = styled.h5`
	margin-top: ${({ theme }) => theme.spacing[2]};
	font-size: ${({ theme }) => theme.spacing[4]};
	font-weight: 300;
	color: ${({ theme }) => theme.colors.white50};
`;

