import { useDispatch, useSelector } from "react-redux";
import * as Styled from './notifications.styles';
import { useEffect, useMemo, useState } from "react";
import Spinner from "../../components/common/spinner";
import { fetchAllNotifications, selectNotifications } from "./notificationsSlice";
import NotificationItem from "./notificationItem";
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE } from "./constants";
import { selectCommunities } from "../communities/communitiesSlice";
import { useTranslation } from "react-i18next";

export default function Notifications() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { loading, all: notifications } = useSelector(selectNotifications);
	const [activeTab, setActiveTab] = useState(NOTIFICATION_TYPE.INVITATION);


	const notificationsByType = useMemo(() => {
		const notificationsByType = {};
		notifications.forEach(notification => {
			const type = notification.type;
			if (!notificationsByType[type]) {
				notificationsByType[type] = [];
			}
			notificationsByType[type].push(notification);
		});
		return notificationsByType;
	}, [notifications]);

	// const newNotificationsByType = useMemo(() => {
	// 	const newNotificationsByType = {};
	// 	Object.keys(notificationsByType).forEach(type => {
	// 		newNotificationsByType[type] = notificationsByType[type]
	// 			.filter(notification => notification.status === NOTIFICATION_STATUS.UNREAD ||
	// 				notification.status === NOTIFICATION_STATUS.PENDING
	// 			).length;
	// 	});
	// 	return newNotificationsByType;
	// }, [notificationsByType]);

	const pendingNotifications = useMemo(() => {
		let res = [];

		if (notificationsByType[activeTab]) {
			res = notificationsByType[activeTab].filter(notification => notification.status === NOTIFICATION_STATUS.PENDING);
		}

		return res;
	}, [activeTab, notificationsByType]);

	const closedNotifications = useMemo(() => {
		let res = [];

		if (notificationsByType[activeTab]) {
			res = notificationsByType[activeTab].filter(notification => notification.status !== NOTIFICATION_STATUS.PENDING);
		}

		return res;
	}, [activeTab, notificationsByType]);

	return (
		<Styled.Container>
			{loading && <Spinner />}
			<Styled.Header>{t('common.notifications')}</Styled.Header>
			{/*<Styled.Tabs>
				<Styled.Tab
					$active={activeTab === NOTIFICATION_TYPE.INVITATION}
					onClick={() => setActiveTab(NOTIFICATION_TYPE.INVITATION)}
				>
					Invitations ({newNotificationsByType[NOTIFICATION_TYPE.INVITATION]})
				</Styled.Tab>
				<Styled.Tab
					$active={activeTab === NOTIFICATION_TYPE.REQUEST}
					onClick={() => setActiveTab(NOTIFICATION_TYPE.REQUEST)}
				>
					Requests ({newNotificationsByType[NOTIFICATION_TYPE.REQUEST]})
				</Styled.Tab>
				<Styled.Tab
					$active={activeTab === NOTIFICATION_TYPE.GENERAL}
					onClick={() => setActiveTab(NOTIFICATION_TYPE.GENERAL)}
				>
					General ({newNotificationsByType[NOTIFICATION_TYPE.GENERAL]})
				</Styled.Tab>
			</Styled.Tabs>
			*/}
			<Styled.Pending>{t('common.pending')}</Styled.Pending>
			{pendingNotifications.length === 0 && <Styled.EmptyNotifications>{t("notifications.no_pending_notifications")}</Styled.EmptyNotifications>}
			<Styled.List>
				{pendingNotifications.filter(notification => notification.status === NOTIFICATION_STATUS.PENDING)
					.map(notification => (
						<NotificationItem
							key={notification.id}
							notification={notification}
						/>
					))
				}
			</Styled.List>
			<Styled.Closed>{t('common.closed')}</Styled.Closed>
			{closedNotifications.length === 0 && <Styled.EmptyNotifications>{t("notifications.no_closed_notifications")}</Styled.EmptyNotifications>}
			<Styled.List>
				{closedNotifications.filter(notification => notification.status !== NOTIFICATION_STATUS.PENDING)
					.map(notification => (
						<NotificationItem
							key={notification.id}
							notification={notification}
						/>
					))
				}
			</Styled.List>
		</Styled.Container>
	);
}

