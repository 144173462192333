import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
`;

export const Button = styled.div`
	padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[1]}`};
	border-radius: ${({ theme }) => theme.spacing[3]};
	background-color: ${({ theme, $sharp }) => $sharp ? theme.colors.white : theme.colors.white10};
	cursor: pointer;

	svg path {
		fill: ${({ theme, $sharp }) => $sharp ? theme.colors.black : theme.colors.white};
	}
`;

export const Menu = styled.div`
	min-width: ${({ theme }) => theme.spacing[48]};
	position: absolute;
	left: ${({ $position }) => $position.x}px;
	top: ${({ $position }) => $position.y}px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[1]};
	transform: ${({ $position }) => `translateX(${$position.translateX})`};
`;

export const MenuItem = styled.div`
	padding: ${({ theme }) => theme.spacing[4]};
	border-radius: ${({ theme }) => theme.spacing[1]};
	background-color: ${({ theme }) => theme.colors.white};
	text-align: right;
	font-size: ${({ theme }) => theme.spacing[4]};
	color: ${({ theme }) => theme.colors.black};
	cursor: pointer;
`;

