import { Canvas, Line, Gradient, Group, Image, FabricImage, Circle } from 'fabric';
import {calculateScoreScaleFactor, fillWholeLine, panToMember} from '../helpers';
import { createUserGroup } from './userGroup';
import { initPinchZoom } from '../pinchZoom';
import {
	ATTESTATION_LINE_WIDTH,
	GRADUATION_LINE_DISTANCE_THRESHOLD, GRADUATION_LINE_HEIGHT_REDUCTION_FACTOR,
	GRADUATION_LINE_MAX_HEIGHT, GRADUATION_LINE_MAX_WIDTH, GRADUATION_LINE_COLOR,
	LINE_GRADIENT_START_COLOR, LINE_GRADIENT_END_COLOR,
	INITIAL_CIRCLE_RADIUS,
	DRAG_CURRENT_INDICATOR_COLOR, DRAG_CURRENT_INDICATOR_HEIGHT,
	USER_GROUP_TEXT_OFFSET, DRAG_INDICATORS_WIDTH, DRAG_REMOVE_INDICATOR_SCALE, DRAG_CURRENT_INDICATOR_OPACITY
} from '../constants';
import {
	mouseDownHandler,
	mouseMoveHandler,
	mouseUpHandler,
	mouseWheelHandler,
	objectModifiedHandler,
	objectMovingHandler,
	pointerUpHandler
} from '../eventHandlers';
import { createRipples } from '../animations';
import {transformPosition} from "../../../../../utils/helpers";
import Singleton from "../../../../../classes/Singleton";
import attestationLineX from '../../../../../icons/AttestationLineX.svg';

const pointerHandlerSymbol = Symbol('pointerHandlersAdded');

class AttestationsCanvasManager extends Singleton {
	constructor({ addAttestedMemberId, removeAttestedMemberId, members, canvasElement, theme, onAttestationUpdate, dragState, setIsCanvasPanable }) {
		super();

		this.state = {
			scoreScaleFactor: 1,
			zoom: 1,
			evCache: [],
			prevDiff: -1,
			centerX: canvasElement.parentElement.clientWidth / 2,
			centerY: undefined,//will be set in initialize
			shouldAnimate: false,
		};

		this.setIsCanvasPanable = setIsCanvasPanable;

		this.graduationLineGroup = null;
		this.draggingIndicator = null;
		this.draggingRemoveIndicator = null;
		this.memberObjects = [];

		this.canvasElement = canvasElement;
		this.addAttestedMemberId = addAttestedMemberId;
		this.removeAttestedMemberId = removeAttestedMemberId;
		this.members = members;
		this.theme = theme;
		this.onAttestationUpdate = onAttestationUpdate;
		this.dragState = dragState;

		this.isAwaitingUpdate = false;
		this.state.animationState = 0;//0: no animation, 1: delay before animation, 2: animation in progress

		this.initialize();
	}

	createDraggingIndicatorGroup() {
		this.draggingIndicator = new Line(
			[0, this.state.centerY - DRAG_CURRENT_INDICATOR_HEIGHT,
				0, this.state.centerY + DRAG_CURRENT_INDICATOR_HEIGHT],
			{
				stroke: DRAG_CURRENT_INDICATOR_COLOR,
				strokeWidth: DRAG_INDICATORS_WIDTH,
				selectable: false,
				originX: 'center',
				originY: 'center',
				name: 'draggingIndicator',
				opacity: DRAG_CURRENT_INDICATOR_OPACITY,
			});
		this.draggingIndicator.visible = false;
		this.canvas.add(this.draggingIndicator);

		Image.fromURL(attestationLineX).then((img) => {
			img.set({
				selectable: false,
				name: 'draggingRemoveIndicator',
				originX: 'center',
				originY: 'center',
				top: this.state.centerY,
				left: this.state.centerX,
				scaleX: DRAG_REMOVE_INDICATOR_SCALE,
				scaleY: DRAG_REMOVE_INDICATOR_SCALE,
			});

			img.visible = false;
			this.draggingRemoveIndicator = img;
			this.canvas.add(img);
		});
	}

	async initialize() {
		this.canvas = new Canvas(this.canvasElement, {
			width: this.canvasElement.parentElement.clientWidth,
			height: 200,
			selection: false,
			renderOnAddRemove: true,
		});
		this.canvas.selection = false;

		this.graduationLineGroup = new Group([], {
			selectable: false,
			name: 'graduationLineGroup',
			hoverCursor: 'default',
		});

		this.canvas.add(this.graduationLineGroup);

		this.state.centerY = this.canvas.height / 2;


		this.createDraggingIndicatorGroup();

		initPinchZoom(this.canvas, this.state, this);
		this.setupCanvasHandlers();

		this.state.scoreScaleFactor = calculateScoreScaleFactor(this.canvas, this.members);

		this.addAttestationLine();
		this.updateGraduationLines();
		await this.addMembers();
	}

	setupCanvasHandlers() {
		this.pointermoveHandler = async (e) => {
			const el = document.elementFromPoint(e.clientX, e.clientY);
			if (this.dragState.current.isDragging && el === this.canvas.upperCanvasEl) {
				//show the dragging line
				this.draggingIndicator.visible = true;
				//convert the x position to canvas coordinates
				//get the x position of the mouse
				const x = e.clientX - this.canvasElement.getBoundingClientRect().left;
				const canvasX = transformPosition({ x, y: 0 }, this.canvas).x;
				this.draggingIndicator.set({ x1: canvasX, x2: canvasX });
				this.draggingIndicator.setCoords();
			} else {
				this.draggingIndicator.visible = false;
			}
			this.canvas.requestRenderAll();
		}

		this.pointerupHandler = async (e) => {
			const el = document.elementFromPoint(e.clientX, e.clientY);
			if (this.dragState.current.isDragging && el === this.canvas.upperCanvasEl) {
				const newMemberObject = await pointerUpHandler(e, this.canvas, this.members, this.dragState, this.theme, this.addAttestedMemberId, this.onAttestationUpdate, this.state);

				if (newMemberObject) {
					this.draggingIndicator.visible = false;
					this.canvas.requestRenderAll();
					await createRipples(this.canvas, 3, newMemberObject.left, newMemberObject.top - USER_GROUP_TEXT_OFFSET / this.state.zoom * 2, this.theme);
					this.memberObjects.push(newMemberObject);
				}
				this.dragState.current.isDragging = false;
				this.dragState.current.id = null;
			}
			this.draggingIndicator.visible = false;
			this.draggingRemoveIndicator.visible = false;
			this.canvas.isPanningLeft = false;
			this.canvas.isPanningRight = false;
			clearInterval(this.canvas.panInterval);
		}

		if (!document[pointerHandlerSymbol]) {
			document.addEventListener("pointermove", this.pointermoveHandler);
			document.addEventListener("pointerup", this.pointerupHandler);
			document[pointerHandlerSymbol] = true;
		}

		// Set up canvas event handlers
		this.canvas.on('object:modified', async (opt) => {
			const movedUser = objectModifiedHandler.call(this.canvas, opt, this.members, this.removeAttestedMemberId, this.onAttestationUpdate, this.state);
			if (movedUser) {
				await createRipples(this.canvas, 1, movedUser.left, movedUser.top - USER_GROUP_TEXT_OFFSET / this.state.zoom * 2, this.theme);
			}
		});
		this.canvas.on('object:moving', (e) => {
			const willRemove = objectMovingHandler.call(this.canvas, e, this.state);
			const x = e.target.left;
			this.draggingRemoveIndicator.set({ left: x });
			this.draggingIndicator.set({ x1: x, x2: x });
			if (willRemove) {
				this.draggingIndicator.visible = false;
				this.draggingRemoveIndicator.visible = true;
			} else {
				this.draggingIndicator.visible = true;
				this.draggingRemoveIndicator.visible = false;
			}
			this.draggingRemoveIndicator.setCoords();
			this.draggingIndicator.setCoords();
			this.canvas.renderAll();
		} );
		this.canvas.on('mouse:wheel', (opt) => {
			mouseWheelHandler.call(this.canvas, opt, this.state).then(() => {
				this.updateAfterZoom();
			});
		});
		this.canvas.on('mouse:down', (e) => {
			const draggingMemberX = mouseDownHandler.call(this.canvas, e, this.state)
			// Show the dragging line when dragging starts
			if (draggingMemberX) {
				draggingMemberX.getObjects()[4].set("visible", true);//show name
				this.draggingIndicator.visible = true;
				this.draggingRemoveIndicator.set({ left: e.target.left });
				this.draggingIndicator.setCoords();
			}
		});
		this.canvas.on('mouse:move', (e) => {
			mouseMoveHandler.call(this.canvas, e, this.state);
		});
		this.canvas.on('mouse:up', mouseUpHandler);
	}

	awaitUpdateCompletion() {
		return new Promise((resolve) => {
			const checkUpdate = () => {
				if (!this.isAwaitingUpdate) {
					resolve();
				} else {
					setTimeout(checkUpdate, 50);
				}
			};
			checkUpdate();
		});
	}

	async updateMembers(newMembers) {
		await this.awaitUpdateCompletion();
		this.memberObjects.forEach((obj) => {
			this.canvas.remove(obj);
		});
		this.memberObjects = [];
		this.members = newMembers;
		this.state.scoreScaleFactor = calculateScoreScaleFactor(this.canvas, this.members);
		this.state.shouldAnimate = this.members.filter(member => member.attestation.score > 0).length > 0;

		await this.addMembers();
	}

	drawGraduationLines(leftEdge, rightEdge, height) {

		const zoom = this.canvas.getZoom();

		const x = (leftEdge + rightEdge) / 2;

		// Calculate the on-screen distance between this line and the previous one
		const screenDistanceBetweenLines = (rightEdge - leftEdge) * zoom;

		// Check if the distance between lines is too small after considering zoom
		if (screenDistanceBetweenLines < GRADUATION_LINE_DISTANCE_THRESHOLD) return;

		const adjustedWidth = GRADUATION_LINE_MAX_WIDTH / zoom; //keep the width constant regardless of zoom level
		const adjustedHeight = height / zoom; //keep the height constant regardless of zoom level

		const line = new Line(
			[x, this.state.centerY - adjustedHeight / 2,
				x, this.state.centerY + adjustedHeight / 2],
			{
				stroke: GRADUATION_LINE_COLOR,
				strokeWidth: adjustedWidth,
				selectable: false,
				objectCaching: false,  // Disable object caching for sharper lines
				strokeUniform: true
			});
		this.graduationLineGroup.add(line);

		const nextHeight = height * GRADUATION_LINE_HEIGHT_REDUCTION_FACTOR;

		// Recursively draw lines on the left and right, factoring in viewport transform for distance calculations
		this.drawGraduationLines(leftEdge, x, nextHeight);
		this.drawGraduationLines(x, rightEdge, nextHeight);
	}


	addAttestationLine() {
		const gradient = new Gradient({
			type: 'linear',
			gradientUnits: 'pixels', // or 'percentage', depending on your needs
			coords: {
				x1: INITIAL_CIRCLE_RADIUS,
				y1: this.state.centerY,
				x2: this.canvas.width - INITIAL_CIRCLE_RADIUS,
				y2: this.state.centerY,
			},
			colorStops: [
				{ offset: 0, color: LINE_GRADIENT_START_COLOR },
				{ offset: 1, color: LINE_GRADIENT_END_COLOR }
			]
		});

		this.attestationLine = new Line(
			[INITIAL_CIRCLE_RADIUS, this.state.centerY, this.canvas.width - INITIAL_CIRCLE_RADIUS, this.state.centerY],
			{
				stroke: gradient,
				strokeWidth: ATTESTATION_LINE_WIDTH,
				name: 'attestationLine',
				originX: 'center',
				originY: 'center',
				selectable: false,
				hoverCursor: 'default',
			});

		this.canvas.add(this.attestationLine);
	}

	async addMembers() {
		this.isAwaitingUpdate = true;

		for (const member of this.members) {
			if (member.attestation.score === 0) continue;
			const group = await createUserGroup(member, member.attestation.score, this.state, this.theme);
			this.canvas.add(group);
			this.memberObjects.push(group);
		}
		this.state.shouldAnimate = this.members.filter(member => member.attestation.score > 0).length > 0;
		this.isAwaitingUpdate = false;
	}

	updateGraduationLines() {
		this.graduationLineGroup._objects.forEach((obj) => {
			this.graduationLineGroup.remove(obj);        // Remove from group
		});
		this.drawGraduationLines(INITIAL_CIRCLE_RADIUS, this.canvas.width - INITIAL_CIRCLE_RADIUS, GRADUATION_LINE_MAX_HEIGHT);
	}

	updateDraggingIndicator() {
		const zoom = this.canvas.getZoom();
		this.draggingIndicator.set({ strokeWidth: DRAG_INDICATORS_WIDTH / zoom });
		//make height constant regardless of zoom level
		this.draggingIndicator.set({ y1: this.state.centerY - DRAG_CURRENT_INDICATOR_HEIGHT / zoom,
			y2: this.state.centerY + DRAG_CURRENT_INDICATOR_HEIGHT / zoom });

		this.draggingRemoveIndicator.set({
			scaleX: DRAG_REMOVE_INDICATOR_SCALE / zoom,
			scaleY: DRAG_REMOVE_INDICATOR_SCALE / zoom
		});
	}

	updateAfterZoom() {
		this.attestationLine.strokeWidth = ATTESTATION_LINE_WIDTH / this.canvas.getZoom();
		this.updateGraduationLines();
		this.updateDraggingIndicator();
		this.setIsCanvasPanable(this.canvas.getZoom() !== 1);
	}

	focusOnMember(memberId) {
		panToMember(this.canvas, memberId, this.state);
	}

	dispose() {
		if (this.canvas) {
			this.canvas.clear();
			this.canvas.dispose();
		}
	}

	onSave(attestations) {
		this.state.shouldAnimate = attestations.filter(attestation => attestation.score > 0).length > 0;
		fillWholeLine(this.canvas, this.state);
	}
}

export default AttestationsCanvasManager;
