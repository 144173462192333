import styled from 'styled-components';
import SelectEvent from './events/selectEvent';

export const Container = styled.div`
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[8]}`};
`;

export const Header = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-weight: 300;

	>div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	p {
		text-transform: capitalize;
		font-size: ${({ theme }) => theme.spacing[4.5]};
		span {
			font-weight: 600;
		}
	}	

	h1 {
		font-size: ${({ theme }) => theme.spacing[10]};
		text-transform: capitalize;
	}
`;

export const SubHeader = styled.div`
	padding-top: ${({ theme }) => theme.spacing[6]};
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Tabs = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing[3]};
`;

export const TabItem = styled.button`
	background-color: ${({ theme, $active }) => $active ? theme.colors.white : theme.colors.white10};
	text-transform: capitalize;
	color: ${({ theme, $active }) => $active ? theme.colors.black : theme.colors.white50};
	padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[6]}`};
	border: none;
	border-radius: 100px;
	cursor: pointer;
`;

export const SelectEventDropdown = styled(SelectEvent)`
	flex-grow: 1;
	max-width: ${({ theme }) => theme.spacing[60]};
`;

export const List = styled.ul`
	margin-top: ${({ theme }) => theme.spacing[5]};
	display: flex;
	flex-direction: column;
`;

export const ListItem = styled.li`
	display: flex;
	gap: ${({ theme }) => theme.spacing[4]};
	cursor: pointer;
	padding: ${({ theme }) => `${theme.spacing[5]} 0`};
	${({ theme, $index }) => `
		${$index > 0 ? `border-top: 1px solid ${theme.colors.white10};` : ''}	
	`}
`;

export const CommunityAvatar = styled.div`
	width: ${({ theme }) => theme.spacing[22]};
	height: ${({ theme }) => theme.spacing[22]};
	border-radius: 50%;
	border: 2px solid ${({ theme }) => theme.colors.white10};
	flex-shrink: 0;
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		vertical-align: middle;
		border-radius: 50%;
	}
`;

export const CommunityDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: ${({ theme }) => theme.spacing[3]};
	color: ${({ theme }) => theme.colors.white};
	
	span:nth-child(1) {
		padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[3]}`};
		background-color: ${({ theme }) => theme.colors.white10};
		border-radius: ${({ theme }) => theme.spacing[3]};
		font-size: ${({ theme }) => theme.spacing[3]};
	}

	span:nth-child(2) {
		font-size: ${({ theme }) => theme.spacing[5]};
		font-weight: 600;
	}

	span:nth-child(3) {
		font-size: ${({ theme }) => theme.spacing[4]};
		color: ${({ theme }) => theme.colors.white50};
	}
`;

export const CreateButton = styled.button`
	margin-top: ${({ theme }) => theme.spacing[4]};
	padding: ${({ theme }) => theme.spacing[2]};
	background-color: ${({ theme }) => theme.colors.blue[500]};
`;
