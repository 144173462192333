import React, { useCallback, useEffect, useState } from 'react';
import * as Styled from './auth.styles';
import { usePopup } from '../../../providers/PopupContextProvider';
import { openZKEdDSAEventTicketPopup, useZupass } from '../../../utils/zupass';
import { useDispatch } from 'react-redux';
import { setUser } from '../userSlice';
import { getAuthData, getZupaassNonce } from '../../../app/api';
import Spinner from '../../../components/common/spinner';
import { useTranslation } from 'react-i18next';

export default function ZupassButton({ text, className, disabled, active, onAuthStart, onAuthFail, onAuthSuccess, verify }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [isLoading, setLoading] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const { showPopup, closePopup } = usePopup();

	const onAuth = useCallback(async data => {
		const userData = await getAuthData();
		dispatch(setUser(userData));
		setShowSpinner(false);
		onAuthSuccess();
	}, [dispatch, onAuthSuccess]);

	const onAuthFailed = useCallback((err) => {
		setShowSpinner(false);
		showPopup({
			title: err.message,
			buttons: [
				{
					label: t('common.close'),
					onClick: () => {
						closePopup();
					},
				},
			]
		});
		onAuthFail();
	}, [showPopup, t, onAuthFail, closePopup]);

	const { state } = useZupass({ onAuth, onAuthFailed, verify });

	const zupassClickHandler = useCallback(async () => {
		setLoading(true);
		setShowSpinner(true);
		onAuthStart();
	}, [onAuthStart]);


	useEffect(() => {
		(async () => {
			if (isLoading) {
				const nonce = await getZupaassNonce();
				setLoading(false);

				openZKEdDSAEventTicketPopup(
					{
						revealAttendeeEmail: true,
						revealAttendeeName: true,
						revealIsConsumed: true,
						revealEventId: true,
					},
					/* eslint-disable no-undef */
					BigInt(nonce),
					['6f5f194b-97b5-5fe9-994d-0998f3eacc75'],
					[]
				);
			}
		})();
	}, [isLoading]);

	return (
		<>
			{showSpinner && <Spinner />}
			<button className={className + (active ? ' active' : '')} onClick={zupassClickHandler} disabled={disabled}>
				{text}
			</button>
		</>
	);
}
