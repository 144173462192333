import React, { useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as Styled from "./styles";
import { POPUP_TYPE } from "../popup/constants";
import { useTranslation } from "react-i18next";

export default function CropBox({ src, onCrop, onCancel }) {
	const { t } = useTranslation();
	const cropperRef = useRef(null);
	const [minCropBoxWidth, setMinCropBoxWidth] = useState(100);
	const [isOpen, setIsOpen] = useState(true);
	const cropHandler = () => {
		const cropper = cropperRef.current?.cropper;
		setIsOpen(false);
		cropper.getCroppedCanvas({ width: 500, height: 500, }).toBlob(blob => {
			onCrop(blob);
		});
	};

	function onInit(instance) {
		setMinCropBoxWidth(instance.getContainerData().width);
	}

	function onReady() {
		const containerdata = cropperRef.current?.cropper.getContainerData();
		setMinCropBoxWidth(Math.min(containerdata.width, containerdata.height, 100));
	}

	return (
		<Styled.Modal isOpen={isOpen} type={POPUP_TYPE.ALERT} lockUI>
			<Styled.Container>
				<Cropper
					src={src}
					style={{ maxHeight: "50vh", width: "100%" }}
					// Cropper.js options
					initialAspectRatio={1 / 1}
					aspectRatio={1 / 1}
					ref={cropperRef}
					minCropBoxHeight={minCropBoxWidth}
					minCropBoxWidth={minCropBoxWidth}
					onInitialized={onInit}
					ready={onReady}
					viewMode={2}
				/>
				<div className="buttons">
					<button onClick={onCancel} className="secondary">{t('common.cancel')}</button>
					<button onClick={cropHandler}>{t('common.crop')}</button>
				</div>

			</Styled.Container>
		</Styled.Modal>
	);
};

