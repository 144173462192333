import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";
import * as Styled from './styles';
import { ReactComponent as CommunitiesIcon } from '../icons/communities.svg';
import { ReactComponent as CommunitiesSelectedIcon } from '../icons/communities-selected.svg';
import { ReactComponent as UsersIcon } from '../icons/users.svg';
import { ReactComponent as UsersSelectedIcon } from '../icons/users-selected.svg';
import { ReactComponent as NotificationsIcon } from '../icons/notifications.svg';
import { ReactComponent as NotificationsSelectedIcon } from '../icons/notifications-selected.svg';
import { ReactComponent as NotificationsActiveIcon } from '../icons/notifications-active.svg';
import { useCallback, useEffect, useMemo, useState } from "react";
import { getAuthData } from "./api";
import { fetchAllCommunities, selectSelectedEvent } from "../features/communities/communitiesSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/common/spinner";
import { selectUser, setUser } from "../features/user/userSlice";
import { fetchAllNotifications, selectNotifications } from "../features/notifications/notificationsSlice";
import { NOTIFICATION_STATUS } from "../features/notifications/constants";
import { useTranslation } from "react-i18next";

export default function Root() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const notifications = useSelector(selectNotifications);
	const user = useSelector(selectUser);
	const selectedEvent = useSelector(selectSelectedEvent);

	const [fetched, setFetched] = useState(false);

	const getAllData = useCallback(async dispatch => {
		await dispatch(fetchAllCommunities(selectedEvent));
		await dispatch(fetchAllNotifications());
	}, [selectedEvent])

	const fetchCommunitiesAndNotifications = useCallback(async () => {
		try {
			dispatch(getAllData);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setFetched(true);
		}
	}, [dispatch, getAllData]);

	const fetchUserData = useCallback(async () => {
		try {
			const userData = await getAuthData();
			dispatch(setUser(userData));
			if (location.pathname.startsWith('/login')) {
				navigate('/');
			}
		} catch (error) {
			if (!location.pathname.startsWith('/login') && !location.pathname.startsWith('/signup')) {
				navigate('/login');
			}
		}
	}, [dispatch, location.pathname, navigate]);

	useEffect(() => {
		if (location.pathname === '/') {
			navigate('/communities');
			return;
		}

		if (!user.id) {
			fetchUserData();
		} else if (!fetched || location.pathname === '/communities' || location.pathname === '/notifications') {
			fetchCommunitiesAndNotifications();
		}

		if(user.id && !user.is_profile_complete) {
			navigate('/profile');
		}
	}, [fetchCommunitiesAndNotifications, fetchUserData, fetched, location.pathname, navigate, user.id, user.is_profile_complete]);

	const pendingNotifications = useMemo(() => {
		return notifications.all.some(notification => notification.status === NOTIFICATION_STATUS.PENDING)
	}, [notifications.all]);

	let showSpinner = (!fetched || !user.id) && !location.pathname.startsWith('/login') && !location.pathname.startsWith('/signup');

	return (
		<Styled.Container>
			<Styled.Main>
				{showSpinner ? <Spinner /> : <Outlet />}
			</Styled.Main>
			{!location.pathname.startsWith('/login') && !location.pathname.startsWith('/signup') && !location.pathname.startsWith('/verify') && (
				<Styled.Navigation>
					<NavLink
						to="/communities"
					>
						{location.pathname.startsWith('/communities') ? <CommunitiesSelectedIcon /> : <CommunitiesIcon />}
						<span>{t('common.communities')}</span>
					</NavLink>
					<NavLink
						to="/users"
					>
						{location.pathname.startsWith('/users') ? <UsersSelectedIcon /> : <UsersIcon />}
						<span>{t('common.users')}</span>
					</NavLink>
					<NavLink
						to="/notifications"
					>
						{location.pathname.startsWith('/notifications') ?
							<NotificationsSelectedIcon /> :
							pendingNotifications ? <NotificationsActiveIcon /> : <NotificationsIcon />
						}
						<span>{t('common.notifications')}</span>
					</NavLink>
				</Styled.Navigation>
			)}
		</Styled.Container>
	);
}

