import { combineReducers, configureStore } from '@reduxjs/toolkit';
import communitiesReducer from '../features/communities/communitiesSlice';
import userReducer from '../features/user/userSlice';
import usersReducer from '../features/users/usersSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const rootReducer = combineReducers({
	communities: communitiesReducer,
	user: userReducer,
	users: usersReducer,
	notifications: notificationsReducer,
})

export function setupStore(preloadedState) {
	return configureStore({
		reducer: rootReducer,
		enhancers: (getDefaultEnhancers) => {
			return getDefaultEnhancers().concat(sentryReduxEnhancer);
		},
		preloadedState
	})
} 
