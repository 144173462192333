import styled from 'styled-components';

export const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[2]};

	label {
		padding-left: ${({ theme }) => theme.spacing[5.5]};
		text-transform: capitalize;
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[4]};
		font-weight: 600;
	}

	input {
		width: 100%;
		display: flex;
		padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[5.5]}`};
		padding-left: ${({ theme, $hasIcon }) => $hasIcon ? theme.spacing[13] : theme.spacing[5.5]};
		background-color: ${({ theme }) => theme.colors.white10};
		color: ${({ theme }) => theme.colors.white};
		border-radius: ${({ theme }) => theme.spacing[1.5]};
		border: none;
		font-size: ${({ theme }) => theme.spacing[4]};
		box-sizing: border-box;
		
		&::placeholder {
			text-transform: capitalize;
			color: ${({ theme }) => theme.colors.white25};
		}

		&:focus {
			outline: none;
		}
	}	

	>svg {
		position: absolute;
		left: ${({ theme }) => theme.spacing[5.5]};
		top: 50%;
		transform: translateY(-50%);
	}
`;
