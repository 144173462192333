import React from 'react';
import * as Styled from './progress.styles';

export default function Progress({value = 0}) {
	return (
		<Styled.Outer>
			<Styled.Inner>
				<Styled.Bar width={value}>
				</Styled.Bar>
			</Styled.Inner>
		</Styled.Outer>
	)
}

