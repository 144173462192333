import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCommunities } from "./communitiesSlice";
import { selectUser } from "../user/userSlice";
import * as Styled from './communities.styles';
import MoreMenu from "../../components/common/moreMenu";
import { logoutUser } from "../../app/api";
import { useTranslation } from "react-i18next";

export default function Communities() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const communities = useSelector(selectCommunities);
	const user = useSelector(selectUser);

	const [showJoined, setShowJoined] = useState(true);

	const groupedCommunities = useMemo(() => {
		let res = {
			joined: [],
			discover: []
		};

		communities.forEach((community) => {
			if (user.communities.some((userCommunity) => userCommunity.id === community.id)) {
				res.joined.push(community);
			} else {
				res.discover.push(community);
			}
		});

		return res;
	}, [communities, user.communities]);

	async function logout() {
		await logoutUser();
		navigate('/login');
	}

	useEffect(() => {
		if (groupedCommunities.joined.length === 0 && groupedCommunities.discover.length > 0) {
			setShowJoined(false);
		}
	}, [groupedCommunities]);

	return (
		<Styled.Container>
			<Styled.Header>
				<div>
					<p> {t('common.welcome')}, <span>{user.name?.split(" ")[0]}!</span> </p>
					<MoreMenu items={[
						{ label: 'Create Community', onClick: () => navigate('/communities/create') },
						{ label: 'My Profile', onClick: () => navigate(`/profile`) },
						{ label: 'Logout', onClick: logout },
					]} />
				</div>
				<h1>{t('common.communities')}</h1>
			</Styled.Header>
			<Styled.SubHeader>
				<Styled.Tabs>
					<Styled.TabItem $active={showJoined} onClick={() => setShowJoined(true)}>{t('communities.joined')}</Styled.TabItem>
					<Styled.TabItem $active={!showJoined} onClick={() => setShowJoined(false)}>{t('communities.discover')}</Styled.TabItem>
				</Styled.Tabs>
				<Styled.SelectEventDropdown />
			</Styled.SubHeader>
			<Styled.List>
				{groupedCommunities[showJoined ? 'joined' : 'discover'].map((community, index) => (
					<Styled.ListItem
						key={community.id}
						$index={index}
						onClick={() => navigate(`/communities/${community.id}`)}
					>
						<Styled.CommunityAvatar>
							<img src={community.avatar_thumbnail_url} alt={community.name} />
						</Styled.CommunityAvatar>
						<Styled.CommunityDetails>
							<span>{community.member_count} {t('common.members')}</span>
							<span>{community.name}</span>
							<span>@{community.handle}</span>
						</Styled.CommunityDetails>

					</Styled.ListItem>
				))}
			</Styled.List>
		</Styled.Container>
	);
}

