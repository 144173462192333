import styled from 'styled-components';
import Avatar from '../../../components/common/avatar';
import Info from '../../../components/common/info';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const Header = styled.div`
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} 0`};
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: ${({ theme }) => theme.spacing[2]};
		
		h1 {
			text-transform: capitalize;
			font-size: ${({ theme }) => theme.spacing[4.5]};
			font-weight: 500;
			color: ${({ theme }) => theme.colors.white};
		}
		
		span {
			font-size: ${({ theme }) => theme.spacing[3.5]};
			color: ${({ theme }) => theme.colors.white50};
		}
	}		

	.back-icon {
		position: absolute;
		left: ${({ theme }) => theme.spacing[8]};
		background-color: ${({ theme }) => theme.colors.white10};	
		padding: ${({ theme }) => theme.spacing[1]};
		border-radius: ${({ theme }) => theme.spacing[3]};
		cursor: pointer;
		
		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}
	
	.question-icon {
        position: absolute;
        right: ${({ theme }) => theme.spacing[8]};
        background-color: ${({ theme }) => theme.colors.white10};
        padding: 6px;
        border-radius: ${({ theme }) => theme.spacing[3]};
        cursor: pointer;
    }
`;

export const Tabs = styled.div`
	padding: ${({ theme }) => `0 ${theme.spacing[8]}`};
	margin-top: ${({ theme }) => theme.spacing[8]};
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[2]};
`;

export const TabItem = styled.button`
	text-transform: capitalize;
	padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[5]}`};
	border: none;
	border-radius: 100px;
	color: ${({ theme, $active }) => $active ? theme.colors.black : theme.colors.white50};
	background-color: ${({ theme, $active }) => $active ? theme.colors.white : theme.colors.white10};
	font-size: ${({ theme }) => theme.spacing[4]};
	cursor: pointer;
`;

export const Content = styled.div`
	position: relative;
	margin-top: ${({ theme }) => theme.spacing[5]};
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

export const List = styled.ul`
	padding: ${({ theme }) => `0 ${theme.spacing[8]}`};
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: ${({ $attestationsDisabled, $attestationsMinimized }) =>
		$attestationsDisabled ? 'auto' : $attestationsMinimized ? 'calc(100% - 32px)' : 'calc(100% - 200px)'};
	overflow: auto;
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[4]};
	padding: ${({ theme }) => `${theme.spacing[5]} 0`};
	${({ theme, $index }) => `
		${$index > 0 ? `border-top: 1px solid ${theme.colors.white10};` : ''}	
	`}
	
	>div:last-child {
		width: 25%;
	}
`;

export const UserAvatar = styled(Avatar)`
	flex-shrink: 0;
	img {
		filter: ${({ $attested }) => $attested ? 'grayscale(1)' : 'none'};
	}
	span {
		color: ${({ theme, $attested }) => $attested ? theme.colors.white50 : theme.colors.white};
	}
	cursor: pointer;
`;

export const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-grow: 1;
	flex-shrink: 0;
	gap: ${({ theme }) => theme.spacing[3]};
	color: ${({ theme }) => theme.colors.white};

	span:nth-child(1) {
		font-size: ${({ theme }) => theme.spacing[5]};
		font-weight: 600;
		color: ${({ theme, $attested }) => $attested ? theme.colors.white50 : theme.colors.white};
	}

	span:nth-child(2) {
		font-size: ${({ theme }) => theme.spacing[4]};
		color: ${({ theme }) => theme.colors.white50};
	}
`;

export const InfoBox = styled(Info)`
	margin-top: ${({ theme }) => theme.spacing[10]};
`;

export const MovingAvatar = styled(Avatar)`
	position: absolute;
	z-index: 9999;
	left: 0;
	top: 0;
	display: none;
	transform: translate(-50%, -50%);
	pointer-events: none;
	user-select: none;
`;

export const MinimizedAttestations = styled.div`
	position: relative;
	background: rgb(85,2,32);
	background: linear-gradient(135deg, rgba(85,2,32,1) 0%, rgba(60,5,25,1) 100%, rgba(60,5,25,1) 100%);
	color: ${({ theme }) => theme.colors.white};
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	
	span {
		font-size: ${({ theme }) => theme.spacing[4]};
		font-weight: 500;
	}
	
	svg {
		width: ${({ theme }) => theme.spacing[7]};
		height: ${({ theme }) => theme.spacing[7]};
		position: absolute;
		right: ${({ theme }) => theme.spacing[7]};
		top: 50%;
		transform: translateY(-50%);
	}
`;

