import { Group, Circle, Image, Text } from 'fabric';
import { getInitials } from '../../../../../utils/helpers';
import {
	INITIAL_CIRCLE_RADIUS,
	USER_GROUP_TEXT_OFFSET,
	USER_INITIALS_FONT_SIZE,
	USER_NAME_FONT_SIZE
} from '../constants';
import {getUserGroupTop} from "../helpers";

export async function createUserGroup(user, score, state, theme) {
	const circle = new Circle({
		radius: INITIAL_CIRCLE_RADIUS,
		originX: 'center',
		originY: 'center',
		fill: theme.colors.white10,
	});

	let img;
	if (user?.avatar_thumbnail_url) {
		img = await Image.fromURL(user.avatar_thumbnail_url)
		let circleClipPath = new Circle({
			radius: INITIAL_CIRCLE_RADIUS * 2,
			originX: 'center',
			originY: 'center'
		});
		img.set({
			scaleX: INITIAL_CIRCLE_RADIUS / 50,
			scaleY: INITIAL_CIRCLE_RADIUS / 50,
			clipPath: circleClipPath,
			originX: 'center',
			originY: 'center',
		});
	} else {
		img = new Text(getInitials(user.name), {
			fontSize: USER_INITIALS_FONT_SIZE,
			fill: theme.colors.white,
			originX: 'center',
			originY: 'center'
		});
	}
	const redCircle = new Circle({
		radius: INITIAL_CIRCLE_RADIUS,
		fill: theme.colors.magenta50,
		originX: 'center',
		originY: 'center',
		id: 'redCircle',
		visible: false
	});

	const x = new Text("X", {
		fontSize: INITIAL_CIRCLE_RADIUS,
		fill: theme.colors.white,
		originX: 'center',
		originY: 'center',
		id: "x",
		visible: false
	});

	const name = new Text(user.name, {
		fontSize: USER_NAME_FONT_SIZE,
		fill: theme.colors.white,
		fontFamily: '"Plus Jakarta Sans", sans-serif',
		originX: 'center',
		originY: 'top',
		top: INITIAL_CIRCLE_RADIUS + USER_GROUP_TEXT_OFFSET,
		visible: false
	});

	const scale = 1 / state.zoom;

	const group = new Group([circle, img, redCircle, x, name], {
		left: state.scoreScaleFactor * score,
		top: getUserGroupTop(state),
		originX: 'center',
		originY: 'center',
		hasControls: false,
		// lockMovementY: true,
		hasBorders: false,
		scaleX: scale,
		scaleY: scale,
		memberId: user.id,
		groupId: 'userGroup',
		name: 'userGroup'
	});

	return group;
}
