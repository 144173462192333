import { useNavigate } from 'react-router-dom';
import * as Styled from './styles';
import { ReactComponent as EmailIcon } from '../../../../icons/email.svg';
import { ReactComponent as LockIcon } from '../../../../icons/lock-closed.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { authenticateUser, getAuthData } from '../../../../app/api';
import { isValidEmail } from '../../../../utils/helpers';
import Spinner from '../../../../components/common/spinner';
import { useDispatch } from 'react-redux';
import { setUser } from '../../userSlice';

export default function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [error, setError] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	async function login(e) {
		e.preventDefault();
		try {
			setLoading(true);
			await authenticateUser(email, password);
			const userData = await getAuthData();
			dispatch(setUser(userData));
			navigate('/');
		} catch (e) {
			setError(e.message);
			return;
		} finally {
			setLoading(false);
		}
	}

	const disableButton = !isValidEmail(email) || password === '';

	return (
		<Styled.Container>
			{(loading) && <Spinner />}
			<form onSubmit={login}>
				<Styled.CustomInput
					placeholder={t("common.email")}
					Icon={EmailIcon}
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
				<Styled.CustomInput
					placeholder={t("auth.password")}
					Icon={LockIcon}
					type="password"
					onChange={e => setPassword(e.target.value)}
				/>
				<Styled.ForgotPassword>
					<span onClick={() => navigate('/login/reset-password')}>{t("auth.forgot_password")}</span>
				</Styled.ForgotPassword>
				<Styled.ErrorText $hidden={!Boolean(error)}>{error}</Styled.ErrorText>
				<Styled.Button type="submit" disabled={disableButton}>{t('auth.login')}</Styled.Button>
			</form>
		</Styled.Container>
	);
}

