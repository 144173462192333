import styled from 'styled-components';
import Popup from '../popup/popup';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[4]};

	>button {
	}
`;

export const Modal = styled(Popup)`
	.content {
		padding: ${({ theme }) => theme.spacing[4]};
	}
`;
