import * as Styled from './styles';
import {ReactComponent as InfoIcon} from '../../../icons/info.svg';

export default function Info({ title, description, className }) {
	return (
		<Styled.Container className={className}>
			<InfoIcon />
			<div>
				<h5>{title}</h5>
				<p>{description}</p>
			</div>
		</Styled.Container>
	)
}
