import { useDispatch, useSelector } from "react-redux";
import { selectUsers, setUsers } from "./usersSlice";
import * as Styled from './users.styles';
import { useEffect, useMemo, useState } from "react";
import { getAllUsers, getInvitedMembersInCommunity, inviteUsers } from "../../app/api";
import Spinner from "../../components/common/spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchCommunityMembers, selectCommunitiesLoading, selectCommunityById, selectSelectedEvent } from "../communities/communitiesSlice";
import Checkbox from "../../components/common/checkbox";
import Popup from "../../components/common/popup/popup";
import { ReactComponent as BackIcon } from '../../icons/back.svg';
import { ReactComponent as XIcon } from '../../icons/x-small.svg';
import { ReactComponent as MagnifierIcon } from '../../icons/magnifier.svg';
import { usePopup } from "../../providers/PopupContextProvider";
import { POPUP_TYPE } from "../../components/common/popup/constants";
import Avatar from "../../components/common/avatar";
import { useTheme } from "styled-components";
import { getInitials } from "../../utils/helpers";
import Input from "../../components/common/input";
import { useTranslation } from "react-i18next";
import { selectUser } from "../user/userSlice";

export default function Users() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const { t } = useTranslation();

	const [searchParams] = useSearchParams();
	const communityId = searchParams.get('community');
	const community = useSelector(state => selectCommunityById(state, communityId));
	const selectedEvent = useSelector(selectSelectedEvent);
	const membersLoading = useSelector(selectCommunitiesLoading);
	const user = useSelector(selectUser);
	const users = useSelector(selectUsers);

	const { showPopup, closePopup } = usePopup();

	const [checkedUsers, setCheckedUsers] = useState([]);
	const [pendingUsers, setPendingUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fetched, setFetched] = useState(false);
	const [searchPhrase, setSearchPhrase] = useState('');

	function checkUser(userId) {
		if (checkedUsers.includes(userId)) {
			setCheckedUsers(checkedUsers.filter(id => id !== userId));
		} else {
			setCheckedUsers([...checkedUsers, userId]);
		}
	}

	async function invite() {
		try {
			setLoading(true);
			await Promise.all(checkedUsers.map(userId => inviteUsers(community.id, userId)));
			setPendingUsers([...pendingUsers, ...checkedUsers]);
			setCheckedUsers([]);
			showPopup({
				title: t('messages.invites_sent.title'),
				type: POPUP_TYPE.ALERT,
				lockUI: true,
				buttons: [
					{
						label: t('messages.invites_sent.button'),
						onClick: () => {
							navigate(`/communities/${communityId}`);
							closePopup();
						},
					},
				]
			});
		} catch (error) {
			console.error('Error sending invites:', error);
		} finally {
			setLoading(false);
		}
	}

	function openUserDetails(userId) {
		if(user.id === userId) {
			navigate('/profile');
			return;
		}
		navigate(`/users/${userId}`)
	}

	const filteredUsers = useMemo(() => {
		if (!searchPhrase) {
			return users;
		}
		return users.filter(user => (
			user.name.toLowerCase().includes(searchPhrase.toLowerCase())) ||
			user.handle.toLowerCase().includes(searchPhrase.toLowerCase())
		)
	}, [users, searchPhrase]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				setLoading(true);
				const result = await getAllUsers(selectedEvent);
				dispatch(setUsers(result));
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchUsers();
	}, [dispatch, selectedEvent]);

	useEffect(() => {
		async function fetchInvitedMembers() {
			const members = await getInvitedMembersInCommunity(community.id);
			setPendingUsers(members.map(member => member.invitee_id));
		}

		if (community && !fetched) {
			dispatch(fetchCommunityMembers(community.id));
			fetchInvitedMembers();
			setFetched(true);
		}
	}, [dispatch, community, fetched]);

	return (
		<Styled.Container>
			{(loading || membersLoading) && <Spinner />}
			<Styled.Header>
				{community && <BackIcon onClick={() => navigate(`/communities/${communityId}`)} />}
				<div className="title-and-filter">
					<h1>{t('common.directory')}</h1>
					<Styled.SelectEventDropdown />
				</div>
				<Styled.InputWrapper className="input-wrapper">
					<Input placeholder={t('users.search_for_the_user')} value={searchPhrase} onChange={e => setSearchPhrase(e.target.value)} />
					{searchPhrase.length > 0 ? <XIcon onClick={() => setSearchPhrase('')} /> : <MagnifierIcon />}
				</Styled.InputWrapper>
			</Styled.Header>
			<Styled.List>
				{filteredUsers.map((user, index) => (
					<Styled.ListItem
						key={user.id}
						$index={index}
					>
						<Avatar
							src={user.avatar_thumbnail_url}
							placeholder={getInitials(user.name)}
							width={theme.spacing[22]}
							height={theme.spacing[22]}
							onClick={() => openUserDetails(user.id)}
						/>
						<Styled.UserDetails onClick={() => openUserDetails(user.id)} >
							<span>{user.name}</span>
							<span>@{user.handle}</span>
						</Styled.UserDetails>
						{community && (
							<>
								{pendingUsers.includes(user.id) ? (
									<Styled.Status>{t('users.invitation_pending')}</Styled.Status>
								) : (
									community.members.find(member => member.id === user.id) ? (
										<Styled.Status $member>{t('common.member')}</Styled.Status>
									) : (
										<Checkbox checked={checkedUsers.includes(user.id)} onChange={() => checkUser(user.id)} />
									)
								)}
							</>
						)}
					</Styled.ListItem>
				))}
			</Styled.List>
			<Popup isOpen={checkedUsers.length}>
				<div className='buttons'>
					<button onClick={invite}>{t('users.invite_now')}</button>
				</div>
			</Popup>
		</Styled.Container>
	);
}

