import { styled } from "styled-components";
import Input from "../../../../components/common/input";

export const Container = styled.div`
	width: 100%;
`;

export const CustomInput = styled(Input)`
	margin-top: ${({ theme }) => theme.spacing[7]};
	width: 100%;
	max-width: ${({ theme }) => theme.spacing[120]};
	
	>input::placeholder {
		color: ${({ theme }) => theme.colors.white80};
	}
`;

export const ForgotPassword = styled.div`
	margin-top: ${({ theme }) => theme.spacing[2]};
	width: 100%;
	max-width: ${({ theme }) => theme.spacing[120]};
	text-align: right;
	
	span {
		font-size: ${({ theme }) => theme.spacing[3.5]};
		color: ${({ theme }) => theme.colors.white80};
		text-decoration: underline;
		cursor: pointer;
	}
`;

export const Button = styled.button`
	margin-top: ${({ theme }) => theme.spacing[6]};
	padding: ${({ theme }) => `${theme.spacing[3.5]} ${theme.spacing[8]}`};
	width: 100%;
	max-width: ${({ theme }) => theme.spacing[120]};
	border: none;
	border-radius: 100px;
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.black};
	font-size: ${({ theme }) => theme.spacing[4.5]};
	font-weight: 500;
	text-transform: capitalize;
	cursor: pointer;

	&:disabled {
		background-color: ${({ theme }) => theme.colors.white25};
		color: ${({ theme }) => theme.colors.white50};
		cursor: not-allowed;
	}
`;

export const ErrorText = styled.p`
	margin-top: ${({ theme }) => theme.spacing[6.5]};
	width: 100%;
	height: ${({ theme }) => theme.spacing[3.5]};
	max-width: ${({ theme }) => theme.spacing[120]};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	color: ${({ theme }) => theme.colors.errorRed};
	text-align: center;
	visibility: ${({ $hidden }) => $hidden ? 'hidden' : 'visible'};
`;

