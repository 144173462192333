import { zoom } from "./helpers";

export function initPinchZoom(canvas, state, attestationsCanvasManager) {
	// Install event handlers for the pointer target
	canvas.upperCanvasEl.addEventListener("pointerdown", ev => pointerdownHandler(ev, state));
	canvas.upperCanvasEl.addEventListener("pointermove", ev => pointermoveHandler(ev, canvas, state, attestationsCanvasManager));

	// Use same handler for pointer{up,cancel,out,leave} events since
	// the semantics for these events - in this app - are the same.
	canvas.upperCanvasEl.addEventListener("pointerup", ev => pointerupHandler(ev, state));
	canvas.upperCanvasEl.addEventListener("pointercancel", ev => pointerupHandler(ev, state));
	canvas.upperCanvasEl.addEventListener("pointerout", ev => pointerupHandler(ev, state));
	canvas.upperCanvasEl.addEventListener("pointerleave", ev => pointerupHandler(ev, state));
}

function pointerdownHandler(ev, state) {
	// The pointerdown event signals the start of a touch interaction.
	// This event is cached to support 2-finger gestures
	state.evCache.push(ev);
}

function pointermoveHandler(ev, canvas, state, attestationsCanvasManager) {
	// This function implements a 2-pointer horizontal pinch/zoom gesture.
	//
	// If the distance between the two pointers has increased (zoom in),
	// the target element's background is changed to "pink" and if the
	// distance is decreasing (zoom out), the color is changed to "lightblue".
	//
	// This function sets the target element's border to "dashed" to visually
	// indicate the pointer's target received a move event.

	// Find this event in the cache and update its record with this event
	const index = state.evCache.findIndex(
		(cachedEv) => cachedEv.pointerId === ev.pointerId,
	);
	state.evCache[index] = ev;

	// If two pointers are down, check for pinch gestures
	if (state.evCache.length === 2) {
		// Calculate the distance between the two pointers
		const curDiff = Math.abs(state.evCache[0].clientX - state.evCache[1].clientX);
		const middlePoint = state.evCache[0].clientX + curDiff / 2;

		if (state.prevDiff > 0) {
			if (curDiff > state.prevDiff) {
				// The distance between the two pointers has increased
				zoom.call(canvas, (state.prevDiff - curDiff) * 2, middlePoint, state);
			}
			if (curDiff < state.prevDiff) {
				zoom.call(canvas, (state.prevDiff - curDiff) * 2, middlePoint, state);
				// The distance between the two pointers has decreased
			}
			attestationsCanvasManager.updateAfterZoom();
			canvas.isDragging = false;
		}

		// Cache the distance for the next move event
		state.prevDiff = curDiff;
	}
}

function pointerupHandler(ev, state) {
	// Remove this pointer from the cache and reset the target's
	// background and border
	removeEvent(ev, state);

	// If the number of pointers down is less than two then reset diff tracker
	if (state.evCache.length < 2) {
		state.prevDiff = -1;
	}
}

function removeEvent(ev, state) {
	// Remove this event from the target's cache
	const index = state.evCache.findIndex(
		(cachedEv) => cachedEv.pointerId === ev.pointerId,
	);
	state.evCache.splice(index, 1);
}


