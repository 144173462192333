import { useEffect, useState } from 'react';
import { respondToInvitation } from '../../app/api';
import * as Styled from './notificationItem.styles';
import Spinner from '../../components/common/spinner';
import { NOTIFICATION_STATUS } from './constants';
import { useDispatch } from 'react-redux';
import { joinCommunity } from '../user/userSlice';
import { getInitials } from '../../utils/helpers';
import { useTheme } from 'styled-components';
import { usePopup } from '../../providers/PopupContextProvider';
import { POPUP_TYPE } from '../../components/common/popup/constants';
import { updateNotificationStatus } from './notificationsSlice';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function NotificationItem({ notification }) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { showPopup, closePopup } = usePopup();
	const { type, community_id, community_name, created_by_id, sender_name, avatar } = notification;
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(notification.status);

	function respond(accept) {
		async function sendResponse(accept) {
			try {
				setLoading(true);
				await respondToInvitation(community_id, accept);

				showPopup({
					title: accept ? t('messages.you_are_member', { community_name }) : t('messages.you_declined_invite', { community_name }),
					type: POPUP_TYPE.ALERT,
					lockUI: true,
					buttons: [
						{
							label: t('common.okay'),
							onClick: () => {
								closePopup();
								dispatch(updateNotificationStatus({
									created_by_id: notification.created_by_id,
									invitee_id: notification.invitee_id,
									community_id: notification.community_id,
									status: accept ? NOTIFICATION_STATUS.ACCEPTED : NOTIFICATION_STATUS.REJECTED
								}))
							},
						},
					]
				});
				// setStatus(accept ? NOTIFICATION_STATUS.ACCEPTED : NOTIFICATION_STATUS.REJECTED);
				if (accept) {
					dispatch(joinCommunity(community_id));
				}
			} catch (error) {
				console.error(error);
			}
			finally {
				setLoading(false);
			}
		}

		sendResponse(accept);
	}

	const notificationText = <p>
		<Trans
			i18nKey="notifications.invitation_message"
			values={{ community_name, sender_name }}
			components={{ span: <span onClick={() => navigate(`/communities/${community_id}`)} /> }}
		/>
	</p>;

	return (
		<Styled.Container>
			{loading && <Spinner />}
			<Styled.UserAvatar
				src={avatar}
				alt={created_by_id}
				placeholder={getInitials(sender_name)}
				width={theme.spacing[16]}
				height={theme.spacing[16]}
			/>
			<Styled.Details>
				{
					status === NOTIFICATION_STATUS.PENDING ? (
						<>
							{notificationText}
							<div>
								<button onClick={() => respond(true)}>{t('common.accept')}</button>
								<button onClick={() => respond(false)}>{t('common.decline')}</button>
							</div>
						</>
					) : (
						<>
							{notificationText}
							<Styled.Status $accepted={status === NOTIFICATION_STATUS.ACCEPTED}>
								{status === NOTIFICATION_STATUS.ACCEPTED ? t('common.accepted') : t('common.declined')}
							</Styled.Status>
						</>
					)
				}
			</Styled.Details>

		</Styled.Container>
	);
}

