import styled from 'styled-components';
import Input from '../../../components/common/input';
import Popup from '../../../components/common/popup/popup';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[8]}`};
	min-height: ${({ theme }) => `calc(100% + ${theme.spacing[10]})`};
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[7]};
	
	>div:first-child {
		svg {
			background-color: ${({ theme }) => theme.colors.white10};	
			border-radius: ${({ theme }) => theme.spacing[3]};
			cursor: pointer;
			
			path {
				fill: ${({ theme }) => theme.colors.white};
			}
		}
	}

	>div:last-child {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: ${({ theme }) => theme.spacing[2]};
		
		h1 {
			font-size: ${({ theme }) => theme.spacing[11]};
			font-weight: 300;
			color: ${({ theme }) => theme.colors.white};
		}
	}		
`;

export const QuestionInput = styled(Input)`
	margin-top: ${({ theme }) => theme.spacing[11]};
	margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const Options = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[5.5]};
	padding: ${({ theme }) => `${theme.spacing[8]} 0`};
	border-top: 1px solid ${({ theme }) => theme.colors.white10};
	border-bottom: 1px solid ${({ theme }) => theme.colors.white10};
`;

export const Option = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing[3]};
	align-items: center;
	
	div:first-child {
		flex-grow: 1;
	}

	svg, div:last-child {
		width: ${({ theme }) => theme.spacing[6]};
		height: auto;
		cursor: pointer;
	}
`;

export const AddOption = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[1.5]};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	align-self: flex-start;
`;

export const Settings = styled.div`
	margin-top: ${({ theme }) => theme.spacing[8]};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[4]};
	
	h2 {
		font-size: ${({ theme }) => theme.spacing[7]};
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white};
	}

	>div {
		display: flex;
		justify-content: space-between;
		align-items: center;

		>div {
			position: relative;
			display: flex;
			flex-direction: column;
			gap: ${({ theme }) => theme.spacing[1.5]};
			
			svg {
				position: absolute;
				top: 0;
				right: -1.5rem;
				width: 1rem;
				height: 1rem;
				cursor: pointer;
			}

		}
		span {
			font-size: ${({ theme }) => theme.spacing[4]};
			font-weight: 300;
			color: ${({ theme }) => theme.colors.white};
		}
	}
`;

export const SettingsInput = styled(Input)`
	width: ${({ theme }) => theme.spacing[20]};
	input {
		text-align: center;
		padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[2]}`};
		${({ hasError, theme }) => hasError && `
			border: 1px solid ${theme.colors.magenta50};
			background-color: ${theme.colors.magenta10};
		`}
	}
	
	/* Chrome, Safari, Edge, Opera */
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}

	/* General */
	input[type=number] {
		appearance: textfield;
	}

`;

export const Footer = styled(Popup)`
	.content {
		gap: ${({ theme }) => theme.spacing[3]};
	}

	.error {
		max-width: 80%;
		align-self: center;
		padding: ${({ theme }) => theme.spacing[3.5]};
		background-color: ${({ theme }) => theme.colors.magenta10};
		border: 1px solid ${({ theme }) => theme.colors.magenta50};
		border-radius: ${({ theme }) => theme.spacing[1]};
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[3.5]};
		font-weight: 300;
	}

	button:disabled {
		opacity: 0.2;
	}
`;

