
import React from 'react';
import * as Styled from './styles';

export default function Textarea({ label, className, ...props }) {
	return (
		<Styled.Wrapper className={className}>
			{label && <label>{label}</label>}
			<textarea {...props} rows={props.rows || 6} />
		</Styled.Wrapper>
	);
}

