import styled from 'styled-components';
import SelectEvent from '../communities/events/selectEvent';

export const Container = styled.div`
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[8]}`};
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;

	>svg {
		margin-bottom: ${({ theme }) => theme.spacing[4]};
		background-color: ${({ theme }) => theme.colors.white10};	
		padding: ${({ theme }) => theme.spacing[1]};
		border-radius: ${({ theme }) => theme.spacing[3]};
		cursor: pointer;
		
		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}

	h1 {
		text-transform: capitalize;
		font-size: ${({ theme }) => theme.spacing[10]};
		line-height: 1.2;
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white};
	}

	>div.title-and-filter {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	>div.input-wrapper {
		margin-top: ${({ theme }) => theme.spacing[7]};
	}	
`;

export const SelectEventDropdown = styled(SelectEvent)`
	flex-grow: 1;
	max-width: ${({ theme }) => theme.spacing[60]};
`;

export const List = styled.ul`
	margin-top: ${({ theme }) => theme.spacing[5]};
	display: flex;
	flex-direction: column;
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[4]};
	cursor: pointer;
	padding: ${({ theme }) => `${theme.spacing[5]} 0`};
	${({ theme, $index }) => `
		${$index > 0 ? `border-top: 1px solid ${theme.colors.white10};` : ''}	
	`}
	>div:last-child {
		flex-shrink: 0;
	}
`;

export const UserDetails = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: ${({ theme }) => theme.spacing[3]};
	color: ${({ theme }) => theme.colors.white};

	span:nth-child(1) {
		font-size: ${({ theme }) => theme.spacing[5]};
		font-weight: 600;
	}

	span:nth-child(2) {
		font-size: ${({ theme }) => theme.spacing[4]};
		color: ${({ theme }) => theme.colors.white50};
	}
`;

export const Status = styled.p`
	text-transform: capitalize;
	font-size: ${({ theme }) => theme.spacing[3]};
	color: ${({ theme, $member }) => $member ? theme.colors.lime75 : theme.colors.white};
`;

export const InputWrapper = styled.div`
	position: relative;

	svg {
		width: ${({ theme }) => theme.spacing[4]};
		height: auto;
		position: absolute;
		right: ${({ theme }) => theme.spacing[4]};
		top: 50%;
		transform: translateY(-50%);
	}
	
	input {
		&::placeholder {
			text-transform: none;
		}
	}
`;

