class Singleton {
	constructor() {
		const constructor = this.constructor;
		if (constructor.instance) {
			return constructor.instance;
		}
		constructor.instance = this;
	}

	static getInstance() {
		if (!this.instance) {
			this.instance = new this();
		}
		return this.instance;
	}
}

export default Singleton;