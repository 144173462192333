import { EdDSATicketPCDPackage } from "@pcd/eddsa-ticket-pcd";
import {
	constructZupassPcdGetRequestUrl,
	openZupassPopup,
	useZupassPopupMessages
} from "@pcd/passport-interface";
import { ArgumentTypeName } from "@pcd/pcd-types";
import { SemaphoreIdentityPCDPackage } from "@pcd/semaphore-identity-pcd";
import {
	ZKEdDSAEventTicketPCDPackage
} from "@pcd/zk-eddsa-event-ticket-pcd";
import { useEffect, useState } from "react";
import { authWithZupass, verifyWithZupass } from "../app/api";

/**
 * Opens a Zupass popup to make a proof of a ZK EdDSA event ticket PCD.
 */
export function openZKEdDSAEventTicketPopup(
	fieldsToReveal,
	watermark,
	validEventIds,
	validProductIds
) {
	const args = {
		ticket: {
			argumentType: ArgumentTypeName.PCD,
			pcdType: EdDSATicketPCDPackage.name,
			value: undefined,
			userProvided: true,
			validatorParams: {
				eventIds: validEventIds,
				productIds: validProductIds,
				notFoundMessage: "No eligible PCDs found"
			}
		},
		identity: {
			argumentType: ArgumentTypeName.PCD,
			pcdType: SemaphoreIdentityPCDPackage.name,
			value: undefined,
			userProvided: true
		},
		validEventIds: {
			argumentType: ArgumentTypeName.StringArray,
			value: validEventIds.length != 0 ? validEventIds : undefined,
			userProvided: false
		},
		fieldsToReveal: {
			argumentType: ArgumentTypeName.ToggleList,
			value: fieldsToReveal,
			userProvided: false
		},
		watermark: {
			argumentType: ArgumentTypeName.BigInt,
			value: watermark.toString(),
			userProvided: false
		},
		externalNullifier: {
			argumentType: ArgumentTypeName.BigInt,
			value: watermark.toString(),
			userProvided: false
		}
	};

	const popupUrl = window.location.origin + "/login/popup";

	const proofUrl = constructZupassPcdGetRequestUrl(
		process.env.REACT_APP_PUBLIC_ZUPASS_URL,
		popupUrl,
		ZKEdDSAEventTicketPCDPackage.name,
		args,
		{
			genericProveScreen: true,
			title: "ZKEdDSA Ticket Proof",
			description: "ZKEdDSA Ticket PCD Request"
		}
	);

	openZupassPopup(popupUrl, proofUrl);
}

export function useZupass({
	onAuth,
	onAuthFailed,
	verify
}) {
	const [pcdStr] = useZupassPopupMessages();
	const [ticketData, setTicketData] = useState(undefined);
	const [state, setState] = useState("initial");

	useEffect(() => {
		(async () => {
			if (pcdStr && !ticketData) {
				setState("verifying");
				try {
					let data;
					if (verify) {
						data = await verifyWithZupass(pcdStr);
					} else {
						data = await authWithZupass(pcdStr);
					}
					setTicketData(data);
					onAuth(data);
					setState("verified");
				} catch (error) {
					onAuthFailed(error);
					setState("error");
				}
			}
		})();
	}, [onAuth, onAuthFailed, pcdStr, ticketData]);

	return { state, ticketData };
}

