import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	all: [ ],
}

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUsers: (state, action) => {
			state.all = action.payload
		},
	},
})

export const { setUsers } = usersSlice.actions

export const selectUsers = state => state.users.all
export const selectUserById = (state, id) => state.users.all.find(user => user.id === id)

export default usersSlice.reducer

