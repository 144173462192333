import React, { useEffect, useState } from 'react';
import { POPUP_ANIMATION_DURATION, POPUP_TYPE } from './constants';
import * as Styled from './popup.styles';

export default function Popup({
	isOpen,
	lockUI = false,
	title,
	text,
	buttons = [],
	children,
	className,
	type = POPUP_TYPE.TOAST,
	onClickOutside
}) {
	const [animate, setAnimate] = useState(false);
	const [show, setShow] = useState(isOpen);

	function containerClickHandler(e) {
		if (e.target === e.currentTarget && onClickOutside) {
			onClickOutside();
		}
	}

	useEffect(() => {
		let timeout;
		if (isOpen) {
			setShow(true);
			timeout = setTimeout(() => setAnimate(true), 100);
		} else {
			setAnimate(false);
			timeout = setTimeout(() => setShow(false), POPUP_ANIMATION_DURATION);
		}
		return () => clearTimeout(timeout);
	}, [isOpen]);

	if (!show) return null;

	return (
		<Styled.Container
			className={(animate ? 'animate' : '') + ' ' + className}
			type={type}
			$buttonsCount={buttons.length}
			onClick={containerClickHandler}
		>
			{lockUI && <Styled.Dropback className='dropback' onClick={onClickOutside} />}
			<Styled.Content type={type} className={(animate ? 'animate' : '') + ' content'}>
				{title && <h3 className='title'>{title}</h3>}
				{text && <p className='text'>{text}</p>}
				<div className='buttons'>
					{buttons.map(({ label, onClick, secondary }) => (
						<button key={label} onClick={onClick} className={secondary && 'secondary'}>{label}</button>
					))}
				</div>
				{children}
			</Styled.Content>
		</Styled.Container>
	);
}
