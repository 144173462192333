import { store } from "..";
import i18n from "../app/i18n";
import { MAX_PRIVACY_LEVEL } from "../features/communities/constants";

export function filterUsers(users, criteria) {
	return users.filter(user =>
		Object.keys(criteria).every(key =>
			criteria[key] === user[key]
		)
	);
}

export function getUserPermissionLevel(communityId) {
	const { user, communities } = store.getState();
	const community = communities.all.find(community => community.id === communityId);

	if (!user || !community) return 0;
	let isMember = user.communities.includes(community.id);
	if (isMember) return MAX_PRIVACY_LEVEL;
	return MAX_PRIVACY_LEVEL - community.privacy_level;
}

export function isUserMember(communityId) {
	const { user } = store.getState();
	if (!user) return false;
	return user.communities.some(community => community.id === communityId);
}

export function getInitials(name) {
	if (name == null) return '';
	return name
		.split(' ')
		.map(word => word[0])
		.join('')
		.slice(0, 3)
		.toUpperCase();
}

export function isValidUrl(url) {
	return url.match(/^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)[a-zA-Z0-9]{1,}(\.[a-zA-Z0-9]{1,})(\.[a-zA-Z0-9]{2,})?/);
}

export function isValidEmail(email) {
	return email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
}

export function validatePassword(password) {
	// Regular expressions to check the conditions
	const minLength = /.{8,}/;
	const hasNumber = /[0-9]/;
	const hasUppercase = /[A-Z]/;
	const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

	// Checking the conditions
	if (!minLength.test(password)) {
		return i18n.t('auth.password_too_short');
	}
	if (!hasNumber.test(password)) {
		return i18n.t('auth.password_no_number');
	}
	if (!hasUppercase.test(password)) {
		return i18n.t('auth.password_no_uppercase');
	}
	if (!hasSpecialChar.test(password)) {
		return i18n.t('auth.password_no_special');
	}

	// If all conditions pass
	return '';
};

export function checkPopupsBlocked() {
	var testPopup = window.open('', '', 'width=100,height=100');

	if (!testPopup || testPopup.closed || typeof testPopup.closed == 'undefined') {
		return true;  // Popups are blocked
	} else {
		testPopup.close();
		return false;  // Popups are not blocked
	}
}

export function transformPosition(pos, canvas) {
	const zoom = canvas.getZoom();
	const panX = canvas.viewportTransform[4];
	const panY = canvas.viewportTransform[5];

	const x = (pos.x - panX) / zoom;
	const y = (pos.y - panY) / zoom;

	return { x, y };
}

export function getGroupElements(canvas, groupId) {
	return canvas.getObjects('group').filter(obj => obj.groupId === groupId);
}

