import styled, { css } from 'styled-components';
import { POPUP_ANIMATION_DURATION, POPUP_TYPE } from './constants';

const toastStyles = css`
	left: 50%;
	bottom: 0;
	width: 100%;
	max-width: ${({ theme }) => theme.screens.md};
	transform: translate(-50%, 100%);
	border-top: 1px solid ${({ theme }) => theme.colors.white10};
	border-radius: 24px 24px 0px 0px;

	&.animate {
		transform: translate(-50%, 0);
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: -1px;
		width: 100%;
		height: 1px;
		background: rgb(85,2,32);
		background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0, 0, 0, 0) 30%, rgba(255,255,255,0.7) 50% , rgba(0, 0, 0, 0) 70%, rgba(17,17,17,0) 100%);
	}
`;

const alertStyles = css`
	left: 50%;	
	top: 50%;
	width: 90%;
	max-width: ${({ theme }) => theme.screens.md};
	padding: ${({ theme }) => `${theme.spacing[13]} ${theme.spacing[12]} ${theme.spacing[12]} ${theme.spacing[12]}`};
	transform: translate(-50%, -50%);
	border-radius: ${({ theme }) => theme.spacing[5]};
	opacity: 0;
	z-index: 999;

	&.animate {
		opacity: 1;
	}
	
	.title {
		margin-bottom: ${({ theme }) => theme.spacing[7]};
	}
	
`;

export const Container = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: ${({ type }) => type === POPUP_TYPE.ALERT ? 999 : 990};
	pointer-events: none;
`;

export const Dropback = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.85);
	pointer-events: all;
`;

export const Content = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[8]} ${theme.spacing[12]} ${theme.spacing[8]}`};
	background: rgb(166,41,124);
	background: linear-gradient(135deg, rgba(166,41,124,1) 0%, rgba(19,17,20,1) 100%);
	color: ${({ theme }) => theme.colors.white50};
	transition: transform ${POPUP_ANIMATION_DURATION}ms ease-in-out;
	pointer-events: all;


	.image {

	}

	.title {
		margin-bottom: ${({ theme }) => theme.spacing[4]};
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[7]};
		font-weight: 500;
		text-align: center;
	}

	.text {
		margin-bottom: ${({ theme }) => theme.spacing[6]};
		font-size: ${({ theme }) => theme.spacing[4]};
		font-weight: 300;
		color: ${({ theme }) => theme.colors.white50};
		text-align: center;
	}

	.sub-text {

	}

	.buttons {
		display: flex;
		justify-content: ${({ $buttonsCount }) => $buttonsCount > 1 ? 'space-between' : 'center'};
		gap: ${({ theme }) => theme.spacing[4]};

		button {
			padding: ${({ theme }) => `${theme.spacing[3.5]} ${theme.spacing[9]}`};
			width: ${({ theme, $buttonsCount }) => $buttonsCount > 1 ? theme.spacing[60] : '100%'};
			max-width: ${({ $buttonsCount }) => `${100 / $buttonsCount - 1}%`};
			font-size: ${({ theme }) => theme.spacing[4.5]};
			font-weight: 500;
			background: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.black};
			text-transform: capitalize;
			border: none;
			border-radius: 100px;
			cursor: pointer;
			
			&.secondary {
				background: ${({ theme }) => theme.colors.white10};
				color: ${({ theme }) => theme.colors.white};
			}
		}
	}

	${({ type }) => type === POPUP_TYPE.TOAST && toastStyles}
	${({ type }) => type === POPUP_TYPE.ALERT && alertStyles}	

`;
