import { styled } from "styled-components";
import Input from "../../../../components/common/input";


export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[8]}`};

	>svg {
		position: absolute;
		top: ${({ theme }) => theme.spacing[8]};
		left: ${({ theme }) => theme.spacing[8]};
		background-color: ${({ theme }) => theme.colors.white10};	
		padding: ${({ theme }) => theme.spacing[1]};
		border-radius: ${({ theme }) => theme.spacing[3]};
		cursor: pointer;
		
		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}
`;

export const Logo = styled.img`
	margin-top: ${({ theme }) => theme.spacing[30]};
	width: ${({ theme }) => theme.spacing[72]};
	height: auto;
`;

export const Headling = styled.h1`
	margin-top: ${({ theme }) => theme.spacing[8]};
	max-width: ${({ theme }) => theme.spacing[96]};
	text-align: center;
	font-size: ${({ theme }) => theme.spacing[12]};
	line-height: 1.25;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.white};
`;

export const Hint = styled.p`
	margin-top: ${({ theme }) => theme.spacing[15]};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	color: ${({ theme }) => theme.colors.white80};
	text-align: center;
`;

export const CustomInput = styled(Input)`
	margin-top: ${({ theme }) => theme.spacing[7]};
	width: 100%;
	max-width: ${({ theme }) => theme.spacing[120]};
	
	>input::placeholder {
		color: ${({ theme }) => theme.colors.white80};
	}
`;

export const PasswordRules = styled.p`
	margin-top: ${({ theme }) => theme.spacing[5]};
	max-width: ${({ theme }) => theme.spacing[96]};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	line-height: 1.25;
	color: ${({ theme }) => theme.colors.white80};
	text-align: center;
`;

export const Button = styled.button`
	margin-top: ${({ theme }) => theme.spacing[4]};
	padding: ${({ theme }) => `${theme.spacing[3.5]} ${theme.spacing[8]}`};
	width: 100%;
	max-width: ${({ theme }) => theme.spacing[120]};
	border: none;
	border-radius: 100px;
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.black};
	font-size: ${({ theme }) => theme.spacing[4.5]};
	font-weight: 500;
	text-transform: capitalize;
	cursor: pointer;

	&:disabled {
		background-color: ${({ theme }) => theme.colors.white25};
		color: ${({ theme }) => theme.colors.white50};
		cursor: not-allowed;
	}
`;

export const LearnMore = styled.a`
	margin-top: ${({ theme }) => theme.spacing[8]};
	color: ${({ theme }) => theme.colors.white80};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	cursor: pointer;
	text-decoration: underline;
`;

export const ErrorText = styled.p`
	margin-top: ${({ theme }) => theme.spacing[12]};
	width: 100%;
	height: ${({ theme }) => theme.spacing[3.5]};
	max-width: ${({ theme }) => theme.spacing[120]};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	color: ${({ theme }) => theme.colors.errorRed};
	text-align: center;
	visibility: ${({ $hidden }) => $hidden ? 'hidden' : 'visible'};
`;

export const Confirmation = styled.div`
	margin-top: ${({ theme }) => theme.spacing[15]};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[8]};
	width: 100%;
	max-width: ${({ theme }) => theme.spacing[120]};
	
	p {
		font-size: ${({ theme }) => theme.spacing[4]};
		line-height: 2;
		color: ${({ theme }) => theme.colors.neon};
		font-weight: 500;
		text-align: center;
	}

	button {
		margin-top: ${({ theme }) => theme.spacing[13]};
		padding: ${({ theme }) => `${theme.spacing[3.5]} ${theme.spacing[8]}`};
		width: 100%;
		max-width: ${({ theme }) => theme.spacing[72]};
		border: none;
		border-radius: 100px;
		background-color: ${({ theme }) => theme.colors.white25};
		color: ${({ theme }) => theme.colors.white};
		font-size: ${({ theme }) => theme.spacing[4.5]};
		font-weight: 400;
		text-transform: capitalize;
		cursor: pointer;
	}
	
`;

