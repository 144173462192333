import * as Styled from "./styles";
import { ReactComponent as DotsIcon } from "../../../icons/dots-vertical.svg";
import { useCallback, useEffect, useState } from "react";
import { PLACEMENT } from "./constants";

export default function MoreMenu({ items, placement = PLACEMENT.BOTTOMLEFT, sharp = false}) {
	const [isOpen, setIsOpen] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0, translateX: '0'});

	function openMenu(event) {
		setIsOpen(true);
		const { width, height, x, y  } = event.currentTarget.getBoundingClientRect();
		switch(placement) {
			case PLACEMENT.TOPLEFT:
				setPosition({ x: x, y: y });
				break;
			case PLACEMENT.TOPRIGHT:
				setPosition({ x: x, y: y });
				break;
			case PLACEMENT.BOTTOMLEFT:
				setPosition({ x: width, y: height + 5, translateX: '-100%'});
				break;
			case PLACEMENT.BOTTOMRIGHT:
				setPosition({ x: x, y: y });
				break;
			default:
				setPosition({ x: x, y: y });
		}
	}

	function closeMenu() {
		setIsOpen(false);
		setPosition({ x: 0, y: 0, translateX: '0'});
	}
	//hide dropdown on outside click
	const handleClickOutside = useCallback(event => {
		if (isOpen && !event.target.closest(`${Styled.Container}`)) {
			setIsOpen(false);
		}
	}, [isOpen]);


	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, [handleClickOutside]);


	return (
		<Styled.Container>
			<Styled.Button onClick={isOpen ? closeMenu : openMenu} $sharp={sharp}>
				<DotsIcon />
			</Styled.Button>
			{isOpen && <Styled.Menu $position={position}>
				{items.map((item, index) => (
					<Styled.MenuItem key={index} onClick={item.onClick}>
						{item.label}
					</Styled.MenuItem>
				))}
			</Styled.Menu>
			}
		</Styled.Container>
	)
}
