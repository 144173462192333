import styled from 'styled-components';
import Popup from '../common/popup/popup';

export const Container = styled(Popup)`
	.content {
		top: auto;
		bottom: 20%;
		padding: ${({ theme }) => `${theme.spacing[7]} ${theme.spacing[24]} ${theme.spacing[7]} ${theme.spacing[5]}`};
		transform: translate(-50%, 0);

		svg {
			position: absolute;
			top: ${({ theme }) => theme.spacing[6]};
			right: ${({ theme }) => theme.spacing[6]};
			width: ${({ theme }) => theme.spacing[4]};
			height: ${({ theme }) => theme.spacing[4]};
		}
	}
	
	.dropback {
		background: transparent;
	}
	
	h3 {
		font-size: ${({ theme }) => theme.spacing[4.5]};
		line-height: 1.25;
		font-weight: 600;
		color: ${({ theme }) => theme.colors.white};
	}
	
	p {
		margin-top: ${({ theme }) => theme.spacing[8]};
		font-size: ${({ theme }) => theme.spacing[4]};
		font-weight: 400;
		color: ${({ theme }) => theme.colors.white};
		line-height: 1.25;
	}
`;
