export const INITIAL_CIRCLE_RADIUS = 25;
export const MAX_ZOOM = 10;
export const REMOVE_THRESHOLD = 70;
export const MAXIMUM_LINE_LENGTH_MULTIPLIER = 5;

export const ATTESTATION_LINE_WIDTH = 3;
export const LINE_GRADIENT_START_COLOR = '#D9CCF3';
export const LINE_GRADIENT_END_COLOR = '#631CFD';

export const GRADUATION_LINE_MAX_HEIGHT = 40;
export const GRADUATION_LINE_MAX_WIDTH = 3;
export const GRADUATION_LINE_HEIGHT_REDUCTION_FACTOR = 0.75;
export const GRADUATION_LINE_DISTANCE_THRESHOLD = 150;
export const GRADUATION_LINE_COLOR = '#FFFFFF';

export const DRAG_CURRENT_INDICATOR_COLOR = '#FFFFFF';
export const DRAG_CURRENT_INDICATOR_HEIGHT = 40;
export const DRAG_CURRENT_INDICATOR_OPACITY = 0.5;
export const DRAG_REMOVE_INDICATOR_SCALE = 0.6;
export const DRAG_INDICATORS_WIDTH = 2;

export const USER_GROUP_TEXT_OFFSET = 5;
export const USER_NAME_FONT_SIZE = 14;
export const USER_INITIALS_FONT_SIZE = 12;
export const USER_GROUP_ANIMATION_DURATION = 1000;
export const USER_GROUP_ANIMTION_DELAY = 800;

export const SCREEN_EDGE_PROPORTION = 0.05;
export const PAN_AMOUNT = 3;