import { EVENTS } from "../../../communities/constants";
import alephLogo from '../../../../icons/aleph.svg';
import ethSafariLogo from '../../../../icons/ethsafari.svg';
import ethSafariUnicornPng from '../../../../images/ethsafari-unicorn.png';
import { theme } from "../../../../app/theme";

export const eventConfig = {
	[EVENTS.ALEPH.toLowerCase()]: {
		logo: alephLogo,
		background: theme.colors.cyan,
	},
	[EVENTS.ETH_SAFARI.toLowerCase()]: {
		logo: ethSafariLogo,
		background: 'linear-gradient( to bottom, #FF59CA 0%, #E75FD8 25%, #BE64E2 43%, #8B6DF5 77%, #4B71FF 100%);',
		decoration: ethSafariUnicornPng,
	},
};

