import styled from 'styled-components';
import Input from '../../../components/common/input';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[8]}`};
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[7]};
	
	>div:first-child {
		display: flex;
		gap: ${({ theme }) => theme.spacing[0.5]};

		>div {
			display: flex;
			flex-direction: column;
			gap: ${({ theme }) => theme.spacing[2]};
			padding-right: ${({ theme }) => theme.spacing[2.5]};

			span:first-child {
				font-size: ${({ theme }) => theme.spacing[3]};
				color: ${({ theme }) => theme.colors.white50};
			}

			span:last-child {
				font-size: ${({ theme }) => theme.spacing[3]};
				color: ${({ theme }) => theme.colors.white};
			}
		}

		>div:not(:first-child) {
			padding-left: ${({ theme }) => theme.spacing[2]};
			border-left: 1px solid ${({ theme }) => theme.colors.white10};
		}
		
		>div:nth-child(1) {
			flex-shrink: 1;
		}
		>div:nth-child(2), >div:nth-child(3) {
			flex-shrink: 0;
		}
		>div:last-child {
			flex-grow: 1;
		}
	}


	>div:last-child {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: ${({ theme }) => theme.spacing[2]};
		
		h1 {
			font-size: ${({ theme }) => theme.spacing[8]};
			line-height: 1.5;
			font-weight: 400;
			color: ${({ theme }) => theme.colors.white};
		}
	}		
`;


export const Options = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing[3]};
	margin-top: ${({ theme }) => theme.spacing[8]};
`;

export const Option = styled.div`
	padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
	height: ${({ theme }) => theme.spacing[16]};
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[3]};
	position: relative;
	overflow: hidden;
	border-radius: ${({ theme }) => theme.spacing[1.5]};
	border: 1px solid ${({ theme, selected }) => selected ? theme.colors.lime10 : theme.colors.white10};
	background-color: ${({ theme, $shouldVote }) => $shouldVote ? theme.colors.white05 : 'transparent'};
	cursor: ${({ $shouldVote }) => $shouldVote ? 'pointer' : 'default'};
`;

export const Checkbox = styled.div`
	width: ${({ theme }) => theme.spacing[8]};
	height: ${({ theme }) => theme.spacing[8]};
	padding: ${({ theme }) => theme.spacing[2]};
	background-color: ${({ theme }) => theme.colors.white50};
	border-radius: 100px;
	border: 1px solid ${({ theme, selected }) => selected ? theme.colors.white : theme.colors.white50};
	>div {
		width: 100%;
		height: 100%;
		border-radius: 100px;
		background-color: ${({ theme, selected }) => selected ? theme.colors.white : 'transparent'};	
	}
`;

export const OptionText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing[2]};
	flex-grow: 1;
	position: relative;
	z-index: 2;
	
	>span:first-child {
		font-size: ${({ theme }) => theme.spacing[4]};
		color: ${({ theme }) => theme.colors.white};
	}
	>span:nth-child(2) {
		font-size: ${({ theme }) => theme.spacing[3]};
		color: ${({ theme }) => theme.colors.lime};
	}
`;

export const OptionResult = styled.span`
	font-size: ${({ theme }) => theme.spacing[4]};
	color: ${({ theme }) => theme.colors.white};
`;

export const Bar = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: ${({ $width }) => `${$width}%`};
	height: 100%;
	background-color: ${({ theme, $voted }) => $voted ? theme.colors.lime10 : theme.colors.white10};
`;

export const Stats = styled.div`
	font-size: ${({ theme }) => theme.spacing[3.5]};
	color: ${({ theme }) => theme.colors.white};
	margin-top: ${({ theme }) => theme.spacing[4]};
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing[1.5]};
`;

export const DeleteButton = styled.button`
	margin-top: ${({ theme }) => theme.spacing[8]};
	align-self: flex-end;
	padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[5]}`};
	background-color: ${({ theme }) => theme.colors.magenta10};
	border-radius: ${({ theme }) => theme.spacing[2]};
	border: 1px solid ${({ theme }) => theme.colors.magenta10};
	color: ${({ theme }) => theme.colors.white};
	font-size: ${({ theme }) => theme.spacing[3.5]};
	font-weight: 300;
`;


