import React, { createContext, useCallback, useContext, useState } from 'react';
import { POPUP_TYPE } from '../components/common/popup/constants';
import Popup from '../components/common/popup/popup';

const PopupContext = createContext();

export const usePopup = () => useContext(PopupContext);

export const PopupContextProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [props, setProps] = useState({
		text: '',
		type: POPUP_TYPE.TOAST,
		buttons: [],
	});

	const showPopup = useCallback(props => {
		setProps(props);
		setIsOpen(true);
	}, []);

	const closePopup = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<PopupContext.Provider value={{ showPopup, closePopup }}>
			{children}
			<Popup
				{...props}
				isOpen={isOpen}
				onClose={closePopup}
			/>
		</PopupContext.Provider>
	);
};

