import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit'
import { getAllCommunities, getCommunityAttestations, getCommunityMembers, getCommunityPolls, getPoll } from '../../app/api';

export const fetchAllCommunities = createAsyncThunk(
	'communities/fetchAllCommunities',
	async (communityId, { getState }) => {
		const eventId = getState().communities.selectedEvent;
		const data = await getAllCommunities(eventId);
		return data;
	}
);

export const fetchCommunityMembers = createAsyncThunk(
	'communities/fetchCommunityMembers',
	async (communityId, { getState, requestId }) => {

		let data = await getCommunityMembers(communityId);
		// data = data.filter(member => member.id !== getState().user.id);
		// Sort data by role, Admins first, Moderators second and Members third
		data.sort((a, b) => {
			if (a.id === getState().user.id) {
				return -1;
			}
			if (b.id === getState().user.id) {
				return 1;
			}
			return a.role - b.role;
		});

		return data;
	}
);

export const fetchCommunityAttestations = createAsyncThunk(
	'communities/fetchCommunityAttestations',
	async (communityId, { getState, requestId }) => {

		const data = await getCommunityAttestations(communityId);

		return data;
	}
);

export const fetchCommunityPolls = createAsyncThunk(
	'communities/fetchCommunityPolls',
	async (communityId, { getState, requestId }) => {
		const data = await getCommunityPolls(communityId);
		return data;
	}
);

export const fetchPoll = createAsyncThunk(
	'communities/fetchPoll',
	async (pollId, { getState, requestId }) => {
		const data = await getPoll(pollId);
		return data;
	}
);

export function fetchCommunityMembersAndAttestations(communityId) {
	return async function(dispatch) {
		await dispatch(fetchCommunityMembers(communityId));
		await dispatch(fetchCommunityAttestations(communityId));
	}
}

const initialState = {
	loading: false,
	error: null,
	all: [],
	selectedEvent: null
}

export const communitiesSlice = createSlice({
	name: 'communities',
	initialState,
	reducers: {
		setCommunities: (state, action) => {
			state.all = action.payload.map(community => ({ ...community, members: [], polls: [] }));
		},
		addCommunity: (state, action) => {
			let { name } = action.payload;
			state.all.push({ id: state.all.length + 1, name: name });
		},
		removeCommunity: (state, action) => {
			state.all = state.all.filter(community => community.id !== action.payload);
		},
		updateMembershipLevels: (state, action) => {
			const { communityId, data } = action.payload;
			const community = state.all.find(community => community.id === communityId);
			community.members.forEach(member => {
				member.membership_level = data[member.id] || 0;
			});
		},
		updateAttestations: (state, action) => {
			const { communityId, data } = action.payload;
			const community = state.all.find(community => community.id === communityId);
			community.members.forEach(member => {
				let attestation = data.find(attestation => attestation.subject_user_id === member.id);
				if (attestation) {
					member.attestation = attestation;
				}
			});
		},
		setSelectedEvent: (state, action) => {
			state.selectedEvent = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllCommunities.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.all = action.payload.map(community => ({ ...community, members: [], polls: [] }));
			})
			.addCase(fetchCommunityMembers.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				const community = state.all.find(community => community.id === action.meta.arg);
				community.members = action.payload;
			})
			.addCase(fetchCommunityAttestations.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				const community = state.all.find(community => community.id === action.meta.arg);
				community.members.forEach(member => {
					const attestation = action.payload.find(attestation => attestation.subject_user_id === member.id);
					member.attestation = {
						community_id: attestation ? attestation.community_id : community.id,
						subject_user_id: attestation ? attestation.subject_user_id : member.id,
						score: attestation ? Number(attestation.score) : 0,
						locked: attestation ? Boolean(attestation.locked) : false
					};
				});
			})
			.addCase(fetchCommunityPolls.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				const community = state.all.find(community => community.id === action.meta.arg);
				community.polls = action.payload;
			})
			.addCase(fetchPoll.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				const community = state.all.find(community => community.id === action.payload.community_id);
				const pollIndex = community.polls.findIndex(poll => poll.id === action.meta.arg);
				if (pollIndex === -1) {
					community.polls.push(action.payload);
				} else {
					community.polls[pollIndex] = action.payload;
				}
			})
			.addMatcher(isPending(fetchAllCommunities, fetchCommunityMembers, fetchCommunityAttestations, fetchCommunityPolls, fetchPoll), (state) => {
				state.loading = true;
				state.error = null;
			})
			.addMatcher(isRejected(fetchAllCommunities, fetchCommunityMembers, fetchCommunityAttestations, fetchCommunityPolls, fetchPoll), (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
	}
})

export const { setCommunities, updateMembershipLevels, updateAttestations, addCommunity, removeCommunity, setSelectedEvent } = communitiesSlice.actions

export const selectCommunitiesLoading = state => state.communities.loading;
export const selectCommunities = state => state.communities.all
export const selectCommunityById = (state, id) => state.communities.all.find(community => community.id === id)
export const selectPollById = (state, id) => state.communities.all.flatMap(community => community.polls).find(poll => poll.id === id)
export const selectSelectedEvent = state => state.communities.selectedEvent;

export default communitiesSlice.reducer
