import { useSearchParams } from 'react-router-dom';
import * as Styled from './styles';
import { ReactComponent as Email } from '../../../../icons/email.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { requestRegistrationEmail } from '../../../../app/api';
import { isValidEmail } from '../../../../utils/helpers';
import Spinner from '../../../../components/common/spinner';

export default function Signup({ onSend }) {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();

	const [email, setEmail] = useState(searchParams.get('email') || '');
	const [error, setError] = useState('');
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);

	async function send(e) {
		e.preventDefault();
		try {
			setLoading(true);
			await requestRegistrationEmail(email);
			setShowConfirmation(true);
			onSend();
		} catch (e) {
			setError(e.message);
			return;
		} finally {
			setLoading(false);
		}
	}

	return (
		<Styled.Container>
			{(loading) && <Spinner />}
			{!showConfirmation ? (
				<>
					<form onSubmit={send}>
						<Styled.EmailInput placeholder={t("common.email")} Icon={Email} value={email} onChange={e => setEmail(e.target.value)} />
						<Styled.ErrorText $hidden={!Boolean(error)}>{error}</Styled.ErrorText>
						<Styled.Button disabled={!isValidEmail(email)}>{t('common.continue')}</Styled.Button>
					</form>
				</>
			) : (
				<Styled.Confirmation>
					<p><Trans i18nKey="auth.signup_confirmation.text1" components={{ br: <br /> }} /></p>
					<p><Trans i18nKey="auth.signup_confirmation.text2" components={{ br: <br /> }} /></p>
					<button onClick={send}>{t("auth.resend_email")}</button>
				</Styled.Confirmation>
			)}
		</Styled.Container>
	);
}

