import * as Styled from './checkbox.styles';
import { ReactComponent as CheckIcon } from '../../icons/check.svg';

export default function Checkbox({ checked, onChange }) {
	return (
		<Styled.Wrapper onClick={onChange}>
			{checked && <CheckIcon />}
		</Styled.Wrapper>
	);
}
