import styled from 'styled-components';
import Popup from '../common/popup/popup';

export const Container = styled(Popup)`
	position: absolute;
	z-index: 9999;

	.content {
		background: ${({ theme }) => theme.colors.purplePrimary};
		padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[10]} ${theme.spacing[16]} ${theme.spacing[10]}`};

		>svg {
			position: absolute;
			top: ${({ theme }) => theme.spacing[6]};
			right: ${({ theme }) => theme.spacing[6]};
			width: ${({ theme }) => theme.spacing[4]};
			height: ${({ theme }) => theme.spacing[4]};
			cursor: pointer;
		}
	}
	
	.dropback {
		background: transparent;
	}
	
	p {
		margin-top: ${({ theme }) => theme.spacing[8]};
		font-size: ${({ theme }) => theme.spacing[4.5]};
		font-weight: 500;
		color: ${({ theme }) => theme.colors.white80};
		line-height: 1.25;
	}
`;

export const Bars = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing[2]};
	align-items: center;
`;

export const Bar = styled.div`
	width: ${({ theme }) => theme.spacing[7]};
	height: ${({ theme }) => theme.spacing[1.5]};
	background: ${({ theme, $complete }) => $complete ? theme.colors.white : theme.colors.lightGray25};
	cursor: pointer;
`;

