import { transformPosition } from "../../../../utils/helpers";
import {
	INITIAL_CIRCLE_RADIUS, PAN_AMOUNT,
	REMOVE_THRESHOLD,
	SCREEN_EDGE_PROPORTION,
} from "./constants";
import {calculateNewAttestations, fillWholeLine, getUserGroupTop, moveObject, zoom} from "./helpers";
import { createUserGroup } from "./objects/userGroup";

export function objectMovingHandler(event, state) {

	const obj = event.target;

	let willRemove = false;

	if (obj.name === 'userGroup') {
		// Calculate the boundaries
		const zoom = this.getZoom();
		const initialTop = getUserGroupTop(state);
		const yDelta = initialTop - obj.top;
		obj.getObjects()[4].set("visible", true);//show name

		const zoomRemovedThreshold = REMOVE_THRESHOLD / zoom;
		if (yDelta > zoomRemovedThreshold || obj.left <= INITIAL_CIRCLE_RADIUS) {
			obj.getObjects()[2].set("visible", true);
			obj.getObjects()[3].set("visible", true);
			willRemove = true;
		} else {
			obj.getObjects()[2].set("visible", false);
			obj.getObjects()[3].set("visible", false);
			willRemove = false;
		}


		const nativeEvent = event.e; // Native event (mouse or touch)
		let clientX;
		if (nativeEvent.touches && nativeEvent.touches.length > 0) { //mobile
			clientX = nativeEvent.touches[0].clientX;
		} else { //desktop
			clientX = nativeEvent.clientX;
		}

		const canvasContainer = this.lowerCanvasEl.getBoundingClientRect();
		const relativeX = clientX - canvasContainer.left;
		const pointerXProportion = relativeX / this.width;

		// Check if close to the left edge
		if (pointerXProportion < SCREEN_EDGE_PROPORTION) {
			if (!this.isPanningLeft) {
				this.isPanningLeft = true;

				this.panInterval = setInterval(() => {
					// Ensure we are not at the edge
					if (this.viewportTransform[4] < 0) {
						this.relativePan({ x: PAN_AMOUNT, y: 0 });
						moveObject(this, obj, state);
					} else {
						clearInterval(this.panInterval);
						this.isPanningLeft = false;
					}
				}, 20);
			}
		} else if (pointerXProportion >= SCREEN_EDGE_PROPORTION && this.isPanningLeft) {
			// Clear interval if not at the edge
			clearInterval(this.panInterval);
			this.isPanningLeft = false;
		}

		// Check if close to the right edge
		if (pointerXProportion > (1 - SCREEN_EDGE_PROPORTION) ) {
			if (!this.isPanningRight) {

				this.isPanningRight = true;
				this.panInterval = setInterval(() => {
					// Ensure we are not at the edge
					if (this.viewportTransform[4] > -(this.width * zoom - window.innerWidth)) {
						this.relativePan({ x: -PAN_AMOUNT, y: 0 });
						moveObject(this, obj, state);
					} else {
						clearInterval(this.panInterval);
						this.isPanningRight = false;
					}
				}, 20);
			}
		} else if (pointerXProportion <= (1 - SCREEN_EDGE_PROPORTION) && this.isPanningRight) {
			// Clear interval if not at the edge
			clearInterval(this.panInterval);
			this.isPanningRight = false;
		}
	}
	moveObject(this, obj, state);
	return willRemove;
}

export function objectModifiedHandler(opt, members, removeAttestedMemberId, onAttestationUpdate, state) {
	if (!opt.e) return;
	const object = opt.target;
	if (object.name === 'userGroup') {
		const zoom = this.getZoom();
		const initialTop = getUserGroupTop(state);
		const yDelta = initialTop - object.top;
		const zoomRemovedThreshold = REMOVE_THRESHOLD / zoom;
		let movedUser;
		if (yDelta > zoomRemovedThreshold || object.left <= INITIAL_CIRCLE_RADIUS) {
			removeAttestedMemberId(object.memberId);
			this.remove(object);
		} else {
			object.top = initialTop;
			object.getObjects()[4].set("visible", false);//hide name
			movedUser = object;
		}

		object.setCoords();
		if (state.shouldAnimate) {
			fillWholeLine(this, state);
		}
		onAttestationUpdate(calculateNewAttestations(members, this));
		return movedUser;
	}
}

export function mouseWheelHandler(opt, state) {
	return new Promise((resolve) => {
		var delta = opt.e.deltaY;

		zoom.call(this, delta, opt.e.offsetX, state);

		opt.e.preventDefault();
		opt.e.stopPropagation();

		resolve();
	});
}


export function mouseDownHandler(opt, state) {
	const obj = opt.target;
	const line = this.getObjects('line').find(obj => obj.name === 'attestationLine');
	if (opt.target && opt.target.name === 'userGroup') {
		if (obj.left >= line.x1) {
			this.isExpanding = true;
			let pressedTime = Date.now();
			const keepExpanding = () => {
				if (Date.now() - pressedTime > 500) moveObject(this, obj, state);
				if (this.isExpanding) requestAnimationFrame(() => keepExpanding());
			}
			keepExpanding();
		}
		return opt.target;
	}
	var evt = opt.e;
	this.isDragging = true;
	this.selection = false;
	this.lastPosX = evt.clientX || evt.touches[0]?.clientX || this.lastPosX;
	this.lastPosY = evt.clientY || evt.touches[0]?.clientY || this.lastPosY;
}

export function mouseMoveHandler(opt, state) {
	if (this.isDragging) {
		var e = opt.e;
		var vpt = this.viewportTransform;
		vpt[4] += (e.clientX || e.touches[0]?.clientX) - this.lastPosX;

		// Optionally, restrict panning to avoid blank spaces vertically
		if (vpt[4] > 0) {
			vpt[4] = 0;
		} else if (vpt[4] < this.getWidth() - this.getWidth() * this.getZoom()) {
			vpt[4] = this.getWidth() - this.getWidth() * this.getZoom();
		}
		this.lastPosX = e.clientX || e.touches[0]?.clientX || this.lastPosX;
		this.renderAll();
	}
}

export function mouseUpHandler(opt) {
	// on mouse up we want to recalculate new interaction
	// for all objects, so we call setViewportTransform
	this.setViewportTransform(this.viewportTransform);
	this.isDragging = false;
	this.isExpanding = false;
	this.selection = true;
}

export async function pointerUpHandler(e, canvas, members, dragState, theme, addAttestedMemberId, onAttestationUpdate, state) {
	e.preventDefault();

	const id = dragState.current.id;

	if (!id || canvas._objects.some(obj => obj.memberId === id)) return;

	const transformedPosition = transformPosition({ x: e.offsetX, y: e.offsetY }, canvas);

	const group = await createUserGroup(
		members.find(user => user.id === id),
		transformedPosition.x / state.scoreScaleFactor,
		state,
		theme,
	);
	canvas.add(group);
	addAttestedMemberId(id);
	onAttestationUpdate(calculateNewAttestations(members, canvas));
	if (state.shouldAnimate) {
		fillWholeLine(canvas, state);
	}

	return group;
}
